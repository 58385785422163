import PropTypes from "prop-types";

export const hotTopicsDefaults = {
  hideOnWeb: false,

  listOfCategories: ["live", "trending"],

  liveType: "live",
  liveWskId: "",
  liveLimit: 3,
  liveLabel: "LIVE",

  trendingType: "trending",
  trendingWskId: "",
  trendingLimit: 6,
  trendingLabel: "TRENDING"
};

const liveDescriptionRefrain = `Default: ${hotTopicsDefaults.liveLimit}. Exceeding 4 is not recommended unless Trending is disabled.`;
const trendingDescriptionRefrain = `Default: ${hotTopicsDefaults.trendingLimit}; more is not recommended.`;

export const HotTopicsPropTypes = {
  customFields: PropTypes.shape({
    // START: General
    hideOnWeb: PropTypes.bool.tag({
      name: "Hide",
      description:
        "If checked, hide the entire live/trending bar (default: false)",
      defaultValue: hotTopicsDefaults.hideOnWeb,
      hidden: false
    }),

    // START: live
    liveType: PropTypes.string.tag({
      defaultValue: hotTopicsDefaults.liveType,
      hidden: true
    }),
    liveWskId: PropTypes.string.tag({
      name: "Live WebSked Collection Id",
      description: `Live collection ID. ${liveDescriptionRefrain}`,
      defaultValue: hotTopicsDefaults.liveWskId,
      hidden: false
    }),
    liveLimit: PropTypes.number.tag({
      name: "Show X items",
      description: `Enter the number of items to show. ${liveDescriptionRefrain}`,
      defaultValue: hotTopicsDefaults.liveLimit,
      hidden: false
    }),
    liveLabel: PropTypes.string.tag({
      name: "Label",
      description: `Label of the trending collection (defaults to ${hotTopicsDefaults.liveLabel})`,
      defaultValue: hotTopicsDefaults.liveLabel,
      hidden: true
    }),

    // START: trending
    trendingType: PropTypes.string.tag({
      defaultValue: hotTopicsDefaults.trendingType,
      hidden: true
    }),
    trendingWskId: PropTypes.string.tag({
      name: "Trending WebSked Collection Id",
      description: `Trending collection ID. ${trendingDescriptionRefrain}`,
      defaultValue: hotTopicsDefaults.trendingWskId,
      hidden: false
    }),
    trendingLimit: PropTypes.number.tag({
      name: "Show X items",
      description: `Enter the number of items to show. ${trendingDescriptionRefrain}`,
      defaultValue: hotTopicsDefaults.trendingLimit,
      hidden: false
    }),
    trendingLabel: PropTypes.string.tag({
      name: "Label",
      description: `Label of the trending collection (defaults to ${hotTopicsDefaults.trendingLabel})`,
      defaultValue: hotTopicsDefaults.trendingLabel,
      hidden: true
    })
  })
};
