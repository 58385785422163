import PropTypes from "@arc-fusion/prop-types";

import get from "lodash.get";
import kebabcase from "lodash.kebabcase";
import upperFirst from "lodash.upperfirst";

import storyCardFilter from "~/content/filters/story-card";
import prismQueryFilter from "~/content/filters/prism-query-filter";
import { calculateAspectRatio } from "~/shared-components/story-card/_children/Image.helpers";
import { isSameImage } from "~/shared-components/story-card/_utilities/helpers";
import {
  isVideoAltArt,
  isHTMLFragmentAltArt,
  isHTMLFileAltArt
} from "../_children/altArt";
import { CLIENT_SIDE_CONTENT_SOURCES } from "../OverrideFormWidget";
import {
  formatContentConfig,
  removeWWW
} from "~/components/utilities/www-remover";

/**
 * Check to see if the ans has any backing content on it
 * @return {Boolean} true if it has an _id field value
 */
export const hasBackingContent = (ans) => {
  return !!ans && !!ans._id;
};

/**
 * Check if there is any other content besides a lead art image
 * */
export const hasCustomContentTantamountToContent = (
  overrides = {},
  noContent = {}
) => {
  const {
    topperLabelShow,
    topperLabel,
    headline,
    headlineHide,
    descriptionText,
    blurbHide,
    alternateArt,
    labelShow,
    label,
    artHide,
    audioHide,
    audioCanonicalUrl,
    audioUrl,
    liveTickerHide,
    liveTickerCanonicalUrl,
    liveGraphicsContentConfig = {},
    relatedLinksHide,
    relatedLinksNum,
    relatedLinksPosition,
    relatedLinksLabel,
    slideshowShow,
    slideshowUrls,
    ctaLabelShow,
    ctaLabel,
    ...rest
  } = overrides || {};

  const { contentService: liveGraphicsContentService } =
    liveGraphicsContentConfig;

  const regex = new RegExp(/relatedLinks_\d/);
  const relatedLinks = Object.entries(rest).filter(
    ([key, val]) => regex.test(key) && val
  );
  const hasRelatedLinksContent =
    !/^None$/i.test(relatedLinksPosition) &&
    relatedLinksNum > 0 &&
    relatedLinks?.length > 0;

  const hasLiveGraphic =
    !!liveGraphicsContentService &&
    !/^no-content$/.test(liveGraphicsContentService);

  const hasSlideshow = !!slideshowShow && !!slideshowUrls;

  // NOTE: Extend this as necessary.
  const thereIsCustomContentTantamountToContent =
    (topperLabelShow && !!topperLabel) ||
    (!headlineHide && !!headline) ||
    (!blurbHide && !!descriptionText) ||
    (!relatedLinksHide && hasRelatedLinksContent) ||
    (!artHide && (hasLiveGraphic || hasSlideshow || !!alternateArt)) ||
    (labelShow && !!label) ||
    (ctaLabelShow && !!ctaLabel) ||
    (!audioHide && (!!audioCanonicalUrl || !!audioUrl)) ||
    (!liveTickerHide && !!liveTickerCanonicalUrl);

  // NOTE: Basically, Object.keys(noContent).length > 1 means there has been a content edit.
  // There is an _id field on unedited content.
  return (
    thereIsCustomContentTantamountToContent ||
    Object.keys(noContent || {}).length > 1
  );
};

/**
 * Returns the anglerfish ID from the image url
 * @param {string} url - an image url
 * @returns {string} - if the url is from anglerfish the unique anglerfish id is rerturned
 */
export function getAnglerfishImageId(url) {
  if (!url || typeof url !== "string" || url.indexOf("arc-anglerfish") === -1)
    return "";
  const re = /.*\/([A-Z0-9]+)(?:_size-normalized)?\.\w+$/;
  // NOTE: try/catch cuz url could be a malformed uri incapable of being decoded
  try {
    return re.test(decodeURIComponent(url)) ? RegExp.$1 : "";
  } catch (e) {
    return re.test(url) ? RegExp.$1 : "";
  }
}

export const getAdjustedArtSize = (overrides) => {
  const { artPosition } = overrides;
  let { artSize } = overrides;
  if (artPosition) {
    if (/(above|below) (head|byline)/i.test(artPosition)) {
      artSize = "Full Width";
    } else if (
      /(left|right)/i.test(artPosition) &&
      (!artSize || artSize === "Full Width")
    ) {
      artSize = "Medium";
    }
  }
  return artSize;
};

/**
 * Takes coverArt properties and appends a cache-busing url param (ts)
 * to the url if cover art is a live image
 * @param {object} overrides -- Object from which to get the data
 * @return {object} -- contains the parsed cover art data.
 */
const getCoverArtProps = ({ overrides }) => {
  let {
    coverArtUrl,
    coverArtWidth,
    coverArtHeight,
    coverArtIsLive = false
  } = overrides;
  const { coverArtCaption, coverArtAltText } = overrides;

  const aspectRatio = calculateAspectRatio(
    get(overrides, "artAspectRatio", "3:2")
  );
  // NOTE: If no width or height, give width an arbitrary value and set height based off of that
  if (coverArtUrl) {
    if (!coverArtWidth || !coverArtHeight) {
      coverArtWidth = 600;
      coverArtHeight = Math.round(coverArtWidth / aspectRatio);
    }
  }
  // Let's make sure coverArtIsLive returns a boolean
  coverArtIsLive = !!coverArtIsLive;

  // START: Add ts (for timestamp) param to coverArtUrl if it's a live image
  const TEN_MINUTES = 10 * 60 * 1000;
  const timestamp =
    Math.floor(new Date().getTime() / TEN_MINUTES) * TEN_MINUTES;
  const delimiter = /\?/.test(coverArtUrl) ? "&" : "?";

  coverArtUrl =
    coverArtUrl && coverArtIsLive
      ? `${coverArtUrl}${delimiter}ts=${timestamp}`
      : coverArtUrl;
  // END: Add timestamp param to coverArtUrl if it's a live image

  return {
    coverArtUrl,
    coverArtCaption,
    coverArtAltText,
    coverArtIsLive,
    coverArtWidth,
    coverArtHeight
  };
};

const getAdjustedAlternateArt = (overrides, outputType) => {
  const { alternateArt } = overrides;

  const contentConfig = get(overrides, "flexFeatureContentConfig", {});

  // START: Video
  // NOTE: If conditions are right, set alternateArt to backing
  // video content or coverArt.
  // This subsequently piggybacks on existing logic and seems like a
  // relatively easy way to achieve the desired outcome
  if (!alternateArt) {
    const contentPath = get(contentConfig, "contentConfigValues.content_path");
    if (isVideoAltArt(contentPath)) {
      const { inlineVideo } = overrides;
      if (inlineVideo) return contentPath;
      const { coverArtUrl = "" } = getCoverArtProps({ overrides });
      if (coverArtUrl) return coverArtUrl;
    }
  }
  // END: Video

  // START: coverArt
  // NOTE: When coverArt has precedence over alternateArt (subset of
  // cases where outputType is jsonapp), set coverArt to alternateArt.
  // This subsequently piggybacks on existing logic and seems like a
  // relatively easy way to achieve the desired outcome
  if (
    outputType === "jsonapp" &&
    /prism-promo/.test(get(contentConfig, "contentService"))
  ) {
    const { coverArtUrl = "" } = getCoverArtProps({ overrides });
    if (
      !!coverArtUrl &&
      !isVideoAltArt(alternateArt) &&
      !isHTMLFragmentAltArt(alternateArt) &&
      !isHTMLFileAltArt(alternateArt)
    ) {
      return coverArtUrl;
    }
  }
  // END: coverArt

  return alternateArt;
};

export const adjustOverrides = (overrides, outputType) => {
  overrides.artSize = getAdjustedArtSize(overrides);
  overrides.alternateArt = getAdjustedAlternateArt(overrides, outputType);

  return overrides;
};

/**
 * Returns the position of the art given the custom field option
 * @param {string} position the custom field option for the image width
 * @return {string} jsonapp value for the art position
 * */
export const getArtPosition = (artPosition = "") => {
  return artPosition ? kebabcase(artPosition) : "art-left-of-headline";
};

/**
 * Returns the width of the art given the size
 * NOTE: This behaves the opposive of getArtSize (see below)
 * @param {string} size the custom field option for the image width
 * @return {string} jsonapp value for the art width
 * */
export const getArtWidth = (size = "") => {
  switch (size.toLowerCase().trim()) {
    case "full width":
    case "xx-large":
    case "x-large":
    case "large":
    case "medium":
    case "small":
    case "x-small":
    case "tiny":
    case "mini":
      return size.toLowerCase().replace(" ", "-");
    default:
      return "medium";
  }
};

/**
 * Returns the size of the art given the width
 * NOTE: This behaves the opposive of getArtWidth (see above)
 * @param {string} width the custom field option for the image width
 * @return {string} custom field value for the art width
 * */
export const getArtSize = (width = "") => {
  switch (width) {
    case "full-width":
      return "Full Width";
    case "xx-large":
      return "XX-Large";
    case "x-large":
      return "X-Large";
    case "x-small":
      return "X-Small";
    case "large":
    case "medium":
    case "small":
    case "tiny":
    case "mini":
      return upperFirst(width);
    default:
      return "Medium";
  }
};

/**
 * Determines if bright or straight promo image should be returned
 *
 * @param {object} content - an ans object
 * @param {object} overrrides - data with lots of info used to determine lead art
 *
 * @return {object} - the promo image
 */
export const getPromoImage = ({ content = {}, overrides = {} }) => {
  const { artImageFallback } = overrides;

  const promoImage = get(
    content,
    "fusion_additions.promo_image",
    get(content, "promo_items.basic", {})
  );

  const headshot = /^headshot/.test(artImageFallback)
    ? get(
        content,
        "fusion_additions.headshot",
        /^headshot-to-default$/.test(artImageFallback) ? promoImage : {}
      )
    : undefined;

  const bright = /^bright/.test(artImageFallback)
    ? get(
        content,
        "fusion_additions.bright",
        /^bright-to-default$/.test(artImageFallback) ? promoImage : {}
      )
    : undefined;

  // NOTE: If image fallback is headshot or bright of some kind,
  // use the headshot or bright as the promoImage
  return headshot || bright || promoImage;
};

/**
 * Decide which image we should be using for Story Cards
 * @param {object} content - an ans object
 * @param {object} overrrides - data with lots of info used to determine lead art
 * @return {object} information about the lead art
 * {
 *  url: String - the url of the image, alternate art (image or video) or inline html content
 *  alt: String - the alt tag for the image (if needed)
 *  caption: String - the default image caption
 *  alternate: Boolean - whether or not this lead image is using the alternate content
 *  anglerfishId: String - the anglerfish ID if it can be fetched
 *  isVideo: Boolean - returns true if the alternate art contains a link to a video
 *  isHtml: Boolean - returns true if the alternate art contains an html fragment or file
 *  htmlFragment: String - contains the html fragment if it's available
 *  htmlFile: String - contains the html file if it's available
 *  type: String - returns the type of lead art content this is based off of the logic for what value
 *    it should be using. Types are below and the logic cascades in the given order:
 *      * video
 *      * html
 *      * alt-image
 *      * primary-slot-image
 *      * promo-items-basic-image
 *      * promo-items-bright
 * }
 */
export const getArtSlotInfo = ({ content = {}, overrides = {} }) => {
  const {
    alternateArt,
    artSize,
    artPosition,
    artAspectRatio,
    liveGraphicsHide,
    liveGraphicsContentConfig
  } = overrides;

  const {
    coverArtUrl,
    coverArtCaption,
    coverArtAltText,
    coverArtIsLive,
    coverArtWidth,
    coverArtHeight
  } = getCoverArtProps({ overrides });

  const promoImage = getPromoImage({ content, overrides });
  const { url: art, provenance } = promoImage;

  // default object values
  const defaultObj = {
    url: null,
    anglerfishId: null,
    image_type: null,
    alt: "",
    caption: "",
    isVideo: false,
    isHtml: false,
    isLiveGraphic: false,
    htmlFile: null,
    htmlFragment: null,
    coverArtUrl: "",
    mediaType: "image",
    artWidth: getArtWidth(artSize),
    artPosition: getArtPosition(artPosition),
    aspectRatio: calculateAspectRatio(artAspectRatio),
    raw: {
      alternateArt: alternateArt || null,
      art,
      coverArt: coverArtUrl || undefined,
      coverArtUrl: coverArtUrl || undefined,
      coverArtCaption:
        coverArtCaption === "undefined" ? undefined : coverArtCaption,
      coverArtAltText:
        coverArtAltText === "undefined" ? undefined : coverArtAltText,
      coverArtIsLive: coverArtIsLive || false,
      coverArtWidth: coverArtWidth === "undefined" ? undefined : coverArtWidth,
      coverArtHeight:
        coverArtHeight === "undefined" ? undefined : coverArtHeight
    }
  };

  // NOTE: abort now if one of the live graphics components is due to show
  if (
    !liveGraphicsHide &&
    get(liveGraphicsContentConfig, "contentService", "").match(
      /^(.*)-live-graphic$/
    )
  ) {
    return {
      ...defaultObj,
      isLiveGraphic: true,
      type: RegExp.$1
    };
  }

  const useThisImage = Object.assign(defaultObj, {
    url: alternateArt || promoImage.url
  });

  // pass along caption and image_type data from promo image if we're using it
  // NOTE: if artSlot is derived from altArt and altArt is the same as promoImage,
  // do not do this cuz altArt takes precedence
  if (!alternateArt && isSameImage(useThisImage, promoImage)) {
    useThisImage.caption = promoImage?.caption;
    useThisImage.credits_caption_display = promoImage?.credits_caption_display;
    useThisImage.credits_display = promoImage?.credits_display;
    useThisImage.image_type = promoImage?.image_type;
  }

  // in case we're not getting any data
  if (!useThisImage || !useThisImage.url) {
    return defaultObj;
  }

  // set an alternative fallback image if alternate art is a video or html
  const alternativeFallbackImage = coverArtUrl || art;

  // if the alternate art is a video use the cover art or fallback to other art
  if (isVideoAltArt(useThisImage.url)) {
    useThisImage.isVideo = true;
    useThisImage.url = alternativeFallbackImage;
  }

  // figure out if this has an anglerfish image id
  useThisImage.anglerfishId = getAnglerfishImageId(useThisImage.url);

  // set alt if image comes from promoImage (underlying art) and it has alt_text
  if (
    useThisImage?.url &&
    useThisImage?.url === promoImage?.url &&
    promoImage?.alt_text
  ) {
    useThisImage.alt = promoImage?.alt_text;
  }

  // set a cover art url
  useThisImage.coverArt = coverArtUrl || useThisImage.url;

  // check if this is html
  useThisImage.isHtml =
    isHTMLFragmentAltArt(useThisImage.url) ||
    isHTMLFileAltArt(useThisImage.url);

  // this needs to use the alternative fallback since alt art is not an image
  if (useThisImage.isHtml) {
    useThisImage.coverArtUrl = alternativeFallbackImage;
    useThisImage.coverArt = alternativeFallbackImage;
    useThisImage.url = alternativeFallbackImage;
    useThisImage.alt = coverArtAltText;
  }

  useThisImage.isCoverArtActive =
    !useThisImage.isVideo &&
    useThisImage.url &&
    useThisImage.url === coverArtUrl;

  // START: altText patch
  // NOTE: true for isHtml and on jsonapp if there is cover art
  // acting as alt art
  if (useThisImage.isCoverArtActive) {
    useThisImage.alt = coverArtAltText;
  }
  // END: altText patch

  // keep track of this as an html fragment
  useThisImage.htmlFragment = isHTMLFragmentAltArt(alternateArt)
    ? alternateArt
    : null;

  // keep track of this as an html file
  useThisImage.htmlFile = isHTMLFileAltArt(alternateArt) ? alternateArt : null;

  // this is a single variable that returns a type corresponding to the
  // logic for the type of lead art content that is being returned
  if (useThisImage.isVideo) {
    useThisImage.type = "video";
  } else if (useThisImage.isHtml) {
    useThisImage.type = "html";
  } else if (useThisImage.url && alternateArt) {
    useThisImage.type = "alt-image";
  } else if (useThisImage.url && /lead_art/.test(provenance)) {
    useThisImage.type = "lead-art";
  } else if (useThisImage.url && /basic/.test(provenance)) {
    useThisImage.type = "promo-items-basic-image";
  } else if (useThisImage.url && /bright/.test(provenance)) {
    useThisImage.type = "promo-items-bright";
  } else if (useThisImage.url && /primary_slot/.test(provenance)) {
    useThisImage.type = "primary-slot-image";
  } else if (useThisImage.url && /credits.by/.test(provenance)) {
    useThisImage.type = "headshot";
  } else {
    useThisImage.type = "fallback-image";
  }
  // append the anglerfish image id to the object
  return useThisImage;
};

export const cardWrapperProps = {
  customFields: PropTypes.shape({
    contentPaths: PropTypes.richtext.isRequired.tag({
      name: "Content Path or Query",
      description:
        "Enter a URL to a story. To render multiple stories, separate the URLs by a comma. To render a feed of stories, pass in a Prism Query."
    }),
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      defaultValue: 1,
      description:
        "If there is too much white space use this to adjust row height"
    })
  })
};

const getClientSideConfig = (contentService, contentConfig, props) => {
  const clientSideInfo = CLIENT_SIDE_CONTENT_SOURCES[contentService];
  if (!clientSideInfo) return undefined;

  const {
    checkIsReady = () => true,
    isServerSideOk = () => false,
    params = {},
    useHook,
    defaultContentConfig = {}
  } = clientSideInfo || {};

  if (isServerSideOk(props)) return undefined;

  let query;
  if (checkIsReady(props) && useHook) {
    // NOTE: Need to harvest and inject client side params into contentConfig
    // so that resolve generates the proper endpoint
    const clientSideContentConfig = Object.keys(params).reduce((acc, param) => {
      if (param in props) {
        acc[param] = props[param];
      }
      return acc;
    }, {});

    query = {
      ...defaultContentConfig,
      ...contentConfig,
      ...clientSideContentConfig
    };
  }

  return {
    useHook,
    query
  };
};

/**
 *
 * @param {string} paths Comma-separated string of URLs. Each can be supplied to a story card,
 * to have a custom feed of stories.
 * @returns {object} an object with the type of feed, and the items that will be within it.
 */
export const feedType = (props) => {
  const { flexFeatureContentConfig = {} } = props;
  // NOTE: Values from flexFeatureContentConfig can be null, hence
  // the extra code below forcing null to some other value

  let { contentService = "prism-promo" } = flexFeatureContentConfig;
  contentService = contentService === null ? "prism-promo" : contentService;

  const isFeed = !contentService.match(/^(prism|prism-promo)$/);

  let filter;
  let type;

  if (isFeed) {
    type = "feed";
    filter = prismQueryFilter;
  } else {
    const contentPath = removeWWW(
      flexFeatureContentConfig?.contentConfigValues?.content_path
    );

    if (/^\//.test(contentPath)) {
      type = "cards";
      filter = storyCardFilter;
    } else {
      type = "no-backing-content";
    }
  }

  const config = {
    type,
    contentConfig: formatContentConfig({
      ...flexFeatureContentConfig,
      filter
    })
  };

  const clientSideConfig = getClientSideConfig(
    contentService,
    config.contentConfig.contentConfigValues,
    props
  );

  return {
    ...config,
    clientSideConfig
  };
};
