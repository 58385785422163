import { useEffect, useState, useRef } from "react";
import { useCache } from "@washingtonpost/asmblr/render";
import { fusionApiHeaders } from "../../next-fusion-compat/constants";
import {
  useEditableContent,
  useContentWithEdits,
  fetchContent
} from "../assembler-render";
import { external } from "../../prism.config";

const useContent = useContentWithEdits;

export const loadingStates = {
  NOT_STARTED: "NOT_STARTED",
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  FINISHED: "FINISHED"
};

const getCacheKey = (config) => JSON.stringify(config);

/**
 * Meant specifically for fetching paginated lists from pcs
 * utilizing current useContent hook
 *
 * @param {*} defaultConfig
 * @param {*} getNextConfig
 * @returns
 */
export const usePaginatedContent = ({
  defaultConfig,
  getNextConfig,
  getCount = () => 1000,
  transform = (data) => data,
  callback = () => true
}) => {
  const cache = useCache();
  const [config, setConfig] = useState(defaultConfig);
  const [items, setItems] = useState([]);
  const [loadingState, setLoadingState] = useState(loadingStates.NOT_STARTED);
  const keysFetched = useRef({});

  const handleLoadMore = () => {
    // only load more if last request was successful
    if (loadingState === loadingStates.SUCCESS) {
      const nextConfig = getNextConfig(config);
      const cacheKey = getCacheKey(nextConfig);
      const oldCacheKey = getCacheKey(config);
      if (cacheKey !== oldCacheKey && !keysFetched.current[cacheKey]) {
        keysFetched.current[cacheKey] = loadingStates.LOADING;
        setConfig(nextConfig);
        setLoadingState(loadingStates.LOADING);
      } else if (cacheKey === oldCacheKey) {
        setLoadingState(loadingStates.FINISHED);
      }
    }
  };

  useEffect(() => {
    const clonedConfig = JSON.parse(JSON.stringify(config));
    // this is so dumb -- for testing in safari
    // we want the cache keys to exactly the same
    // for useContent hook which is ssr'd and this hook
    // which is client side for now for multiview.
    // useContent hook turns "limit" and "offset"
    // from numbers into strings -__-
    if (typeof config?.query?.limit === "number") {
      clonedConfig.query.limit = `${config.query.limit}`;
    }

    if (typeof config?.query?.offset === "number") {
      clonedConfig.query.offset = `${config.query.offset}`;
    }

    const method = clonedConfig.isExternalFetch
      ? "fetchExternalContent"
      : "fetchContent";
    cache[method](clonedConfig)
      .then((data) => {
        // this moves things to responseCache
        cache.wait();
        const transformedData = transform(data);
        const newItems = transformedData?.items || [];
        if (!newItems.length) {
          setLoadingState(loadingStates.FINISHED);
        } else {
          setItems((prev) =>
            [...prev, ...newItems].filter((_, i) => i < getCount(data))
          );
          const hasMoreResults =
            newItems.length + items.length < getCount(data);
          setLoadingState(
            hasMoreResults ? loadingStates.SUCCESS : loadingStates.FINISHED
          );
        }

        callback(transformedData);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoadingState(loadingStates.ERROR);
      });
    // cannot include cache as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return { items, loadingState, handleLoadMore };
};

const fetchAsyncContent = (config) => {
  return fetchContent({
    // url: "http://localhost:3001/api",
    url: external
      ? `${external}/api`
      : "https://www.washingtonpost.com/prism/api",
    headers: fusionApiHeaders,
    ...config
  });
};

export { useContent, useEditableContent, fetchAsyncContent };
