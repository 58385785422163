import { hasLocalStorage } from "~/components/utilities/hasLocalStorage";
import { getSecondaryNavTracking } from "./secondarynav.helpers";

export const HOMEPAGE_URL = "https://washingtonpost.com";

export const initBreakpointMediaQueries = () => ({
  mx: "(min-width: 1400px)",
  lg: "(min-width: 1150px) and (max-width: 1399px)",
  md: "(min-width: 900px) and (max-width: 1149px)",
  sm: "(min-width: 768px) and (max-width: 899px)",
  xs: "(max-width: 767px)"
});

export const parseLocalStorageItem = (lsKey) => {
  let parsedLsItem;
  try {
    parsedLsItem = JSON.parse(lsKey);
  } catch (error) {
    // console.log(error);
  }
  return parsedLsItem;
};

export const addVisitedLink = (lsKey, href, linksObject, clickTime) => {
  /**
   * 1. Make sure the link doesn't already exist in localStorage
   * 2. If it does (maybe revisiting the story), update the clickTime
   */
  if (!linksObject[href]) {
    linksObject = { ...linksObject, ...{ [href]: { clickTime } } };
  }
  const stringifiedLinks = JSON.stringify(linksObject);
  window.localStorage.setItem(lsKey, stringifiedLinks);
};

export const removeVisitedLink = (lsKey, a, linksObject, date) => {
  /**
   * Check localStorage for the link, "a", and remove
   * it from the object if the display_date, "date", is newer
   * than the last time it was clicked
   */
  const linkClickTime = linksObject[a]?.clickTime;
  // date is a string in the 'year-month-day-time' format
  const displayDate = typeof date === "string" ? Date.parse(date) : date;
  if (linksObject.hasOwnProperty(a)) {
    // if display_date is newer, remove the link from localStorage
    if (linkClickTime < displayDate) {
      delete linksObject[a];
    }
  }
  window.localStorage.setItem(lsKey, JSON.stringify(linksObject));
};

export const wasLinkVisited = (visitedLinks, link) => {
  /**
   * Check if the url is in the localStorage object
   * and apply the visited class back in the component
   * if so
   */
  if ((visitedLinks && !Object.keys(visitedLinks).length) || !link) {
    return false;
  }
  /**
   * visitedLinks, full path: "https://www.washingtonpost.com/video-games/tips/nintendo-switch-games-best/"
   * link, partial path: "/video-games/tips/nintendo-switch-games-best/"
   */
  const linkVisited =
    visitedLinks &&
    Object.keys(visitedLinks).some((element) => {
      return element.includes(link);
    });
  return !!linkVisited;
};

/**
 *
 * @param {string} lsKey
 * @returns {obj} { url: clickTime: { time } } URLs that have been clicked
 */
export const getVisitedLinks = (lsKey) => {
  const hasLs = hasLocalStorage();
  const clickedLinks =
    hasLs && parseLocalStorageItem(window.localStorage.getItem(lsKey));
  return clickedLinks;
};

export const cleanUpVisitedLinks = (lsKey) => {
  const linksObject = parseLocalStorageItem(window.localStorage.getItem(lsKey));
  if (
    !hasLocalStorage() ||
    (linksObject && Object.keys(linksObject).length < 50)
  )
    return;

  const allTheLinks = [...document.links].map((l) => l.href);
  if (linksObject) {
    Object.keys(linksObject).forEach((link) => {
      if (!allTheLinks.includes(link)) {
        delete linksObject[link];
      }
    });
    window.localStorage.setItem(lsKey, JSON.stringify(linksObject));
  }
};

export const visitedLinksHandler = (lsKey, href, addOrRemoveLink, date) => {
  // Want to check for the existence of localStorage first
  const hasLs = hasLocalStorage();
  if (!hasLs) return;
  /**
   * Visited links object:
   * {url: { clickTime: time }}
   */
  const visitedLinks = {};

  if (hasLs && !window.localStorage.getItem(lsKey)) {
    window.localStorage.setItem(lsKey, JSON.stringify(visitedLinks));
  }

  const linksObject = parseLocalStorageItem(window.localStorage.getItem(lsKey));
  if (addOrRemoveLink === "add") {
    addVisitedLink(lsKey, href, linksObject, date);
  } else if (addOrRemoveLink === "remove") {
    removeVisitedLink(lsKey, href, linksObject, date);
  }
};

// START: Needed for addItid
/*
 * Given an element (i.e. click target) find
 * 1. the closest targetSel, which will be the target
 * 2. the other targetSel items in the closest containerSel
 *
 * @param {NodeElement} element -- The base element from which to find the closest target and container
 * @param {string} targetSel -- The selector of the target
 * @param {string} containerSel -- The selector of the container
 *
 * @return {obj} - { target, candidates, container }
 */
export const harvestCandidates = (element, targetSel, containerSel) => {
  if (!element) return {};
  const target = element.closest(targetSel);
  if (!target) return {};
  const container = element.closest(containerSel);
  if (!container) return {};
  const candidates = Array.from(container.querySelectorAll(targetSel));
  return { target, container, candidates };
};

/*
 * @param {NodeEl} target -- The target
 * @param {NodeEl, array of} candidates -- The list of candidates
 *
 * @return {string} - the 1-based index (of the form "00n") of the target among
 *   the candidates. This can find many things but here it is used to find:
 *     1. the index of a chain on the page and
 *     2. the index of a feature in a chain.
 */
export const getIndex = ({ target, candidates }) => {
  if (!target || !candidates || !candidates.length) return undefined;
  const index = candidates.findIndex((candidate) => candidate === target);
  return index !== -1 ? (index + 1).toString().padStart(3, 0) : undefined;
};

const linkZoneSel = `:not(.inactive) > [data-chain-name]`;
const linkZoneAttr = "data-chain-name";
const featureSel =
  ".card.hpgrid-item:not(.is-ad), .card.stream-item:not(.is-ad)";

const getChainIndex = (element) =>
  getIndex(harvestCandidates(element, linkZoneSel, "body"));

const getDesktopOrder = (element) =>
  (element.getAttribute("style") || "").replace(
    /.*--dsktp-order:\s?(\d+).*/,
    "$1"
  ) || -1;

const getFeatureIndex = (element) => {
  const props = harvestCandidates(element, featureSel, linkZoneSel);
  const { target, container } = props;
  let { candidates } = props;
  if (container && candidates) {
    const isDesktop = !!container.querySelector(
      ".chain[class*='-dsktp-order']"
    );
    if (isDesktop) {
      // NOTE: Need to get items by table. Within each table, sort by desktop order. Ugh!
      candidates = Array.from(
        container.querySelectorAll(".table-in-grid")
      ).reduce((acc, table) => {
        const sortedList = Array.from(table.querySelectorAll(featureSel)).sort(
          (a, b) => getDesktopOrder(a) - getDesktopOrder(b)
        );
        return [...acc, ...sortedList];
      }, []);
    }
  }
  return getIndex({ target, candidates });
};
// END: Needed for addItid

const isElementEligible = (element) => {
  const domainTest =
    /https?:\/\/[^/]*?(arcpublishing|localhost|wapo|washingtonpost|washpost)[^/]*?\//;
  const relativeTest = /^\//;
  const itidTest = /[?&]itid=/;

  // NOTE: Test that href is valid and itid search param doesn't already exist
  if (element.href)
    return (
      (element.href.match(domainTest) || element.href.match(relativeTest)) &&
      !element.search?.match(itidTest)
    );

  // NOTE: Test that action is valid and itid input doesn't already exist
  if (element.action)
    return (
      (!!element.action.match(domainTest) ||
        !!element.action.match(relativeTest)) &&
      !element.querySelector("#itid")
    );

  return false;
};

const addItid = (
  element,
  event,
  contentType,
  section,
  commercialNode,
  subsection
) => {
  let linkZone;
  let linkGroup;
  let linkDetail;

  const itidParts = [];

  if (isElementEligible(element)) {
    // get itid parts from site-components secondary nav
    const secondaryNav = element.closest(`[data-qa="secondary-nav"]`);
    if (secondaryNav) getSecondaryNavTracking(element, secondaryNav, itidParts);

    const linkZoneEl = element.closest(linkZoneSel);
    if (linkZoneEl) {
      linkZone = linkZoneEl.getAttribute(linkZoneAttr);
    }

    const linkGroupEl = element.closest("[data-link-group]");
    if (linkGroupEl) {
      linkGroup = linkGroupEl.getAttribute("data-link-group");
    }

    const linkZoneIndex = getChainIndex(element);
    const featureIndex = getFeatureIndex(element);

    const linkDetailEl = element.closest("[data-link-detail]");
    if (linkDetailEl) {
      linkDetail = linkDetailEl.getAttribute("data-link-detail");
    }

    if (linkZone && !linkGroup) itidParts.push(linkZone);
    if (linkGroup) itidParts.push(linkGroup);
    if (linkZoneIndex) itidParts.push(`p${linkZoneIndex}`);
    if (featureIndex) itidParts.push(`f${featureIndex}`);
    if (linkDetail) itidParts.push(linkDetail);

    // START: Update href/form here
    if (itidParts.length) {
      if (contentType === "homepage" && !itidParts[0].match(/^hp/)) {
        itidParts.unshift("hp");
      } else if (contentType === "front" && !itidParts[0].match(/^sf/)) {
        // have to do this check for Olympics
        if (commercialNode === "/sports/olympics") {
          itidParts.unshift(`sf_olympics`);
        } else {
          if (subsection) itidParts.unshift(subsection);
          if (section) itidParts.unshift(section);
          itidParts.unshift("sf");
        }
      }

      if (itidParts.length) {
        const itid = itidParts.join("_");
        if (element.href) {
          // NOTE: Update href here
          element.search = `${element.search}${
            element.search ? "&" : "?"
          }itid=${itid}`;
        } else if (element.action) {
          // NOTE: Update form here
          const input = document.createElement("input");
          input.type = "hidden";
          input.id = "itid";
          input.name = "itid";
          input.value = itid;
          element.appendChild(input);
        }
      }
    }
    // END: Update href/form here
  }
};

export const submitHandler = (event, ...args) => {
  const form = event?.target?.closest("form[action]");
  if (form) addItid(form, event, ...args);
};

export const betaClickHandler = (a) => {
  if (
    /beta\.washingtonpost\.com/.test(window.location.host) &&
    /www\.washingtonpost\.com/.test(a.href)
  )
    a.href = a.href.replace(
      /www\.washingtonpost\.com/,
      "beta.washingtonpost.com"
    );
};

export const clickHandler = (event, ...args) => {
  const a =
    event &&
    event.target &&
    event.target.closest &&
    event.target.closest("a[href]");

  if (a) {
    visitedLinksHandler("wpVisitedLinks", a.href, "add", Date.now());
    betaClickHandler(a);
    if (!/contextmenu/i.test(event.type)) addItid(a, event, ...args);
  }
};

/**
 * For scroll depth tracking
 */
export const scrollEventTracking = () => {
  const options = {
    threshold: 0.15
  };

  /**
   * Takes a chain with the "data-link-group"
   * attribute and returns its value(s)
   *
   * @param {object} elem
   * @returns "live-ticker", "opinions"
   */
  const getDataLinkGroups = (elem) => {
    // NOTE: attribute looks like "live-ticker" or "opinions"
    const linkGroupEl = elem.querySelectorAll("[data-link-group]");
    // NOTE: turn the nodelist into an array
    const linkGroupElArray = Array.from(linkGroupEl);
    // NOTE: return an array of data-link-group values like ["live-ticker", "opinions"]
    const dataLinkGroupArray = linkGroupElArray.map((element) => {
      return element.getAttribute("data-link-group");
    });
    const dataLinkGroupString = dataLinkGroupArray.join(", ");
    return dataLinkGroupString;
  };

  // NOTE: (From MDN) Warning: This is a live HTMLCollection.
  // Changes in the DOM will reflect in the array as the changes occur.
  // If an element selected by this array no longer qualifies for the selector,
  // it will automatically be removed. Be aware of this for iteration purposes.
  const chains = Array.from(document.querySelectorAll("[data-gtm-module]"));

  const filteredChains = chains.filter((chain, index) => {
    const tempIndex = index + 1;
    return tempIndex === 1 || tempIndex % 4 === 0;
  });

  const sendToDataLayer = (entries, observer) => {
    entries.forEach((entry) => {
      const elem = entry.target;
      // NOTE: attribute looks like "hp-top-table-main"
      let moduleName = elem.getAttribute("data-gtm-module");
      const dataLinkGroupString = getDataLinkGroups(elem);
      if (dataLinkGroupString) {
        moduleName = `${moduleName}, ${dataLinkGroupString}`;
      }

      if (entry.isIntersecting) {
        global?.window?.dataLayer?.push({
          event: "site-onpage-scroll-event",
          action: "onpage-scroll-impression",
          category: "onpage",
          label: Number(getChainIndex(entry.target)),
          moduleName
        });
        observer.unobserve(elem);
      }
    });
  };

  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver(sendToDataLayer, options);

  return filteredChains.forEach((chain) => {
    observer.observe(chain);
  });
};
