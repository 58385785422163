import get from "lodash.get";

/* Utilities */
import kebabcase from "lodash.kebabcase";
import { fetchProps } from "../_utilities/data";
import { getLink } from "../_utilities/helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";
import { getForm, groomFormForJsonApp } from "./Form.helpers";
import { icons } from "./Icons";
import { logos } from "./Logos";

import {
  getLabelToolbarItems,
  getPrimaryLabelMenuItems
} from "./Label.toolbar";
import { jsonLabelIcons } from "../../../components/features/fronts/utilities/common-front-properties";

const LABEL_PLACEHOLDER_VALUE = "Label";
const LABEL_SECONDARY_PLACEHOLDER_VALUE = "Secondary Label";

// START: icons
export const tokenizeLabelType = (type) => {
  const tokenizedTypes = {
    "Section Large": "package", // deprecated and mapped to package
    "Section Large with Explainer": "package", // deprecated and mapped to package
    Promo: "package-nested", // deprecated and mapped to package-nested
    "Live Updates": "live-updates",
    Package: "package",
    "Package Nested": "package-nested",
    "Section Small": "package-nested", // deprecated and mapped to package-nested
    "Mini ALL-CAPS": "kicker", // deprecated and mapped to kicker
    "Exclusive Pill": "exclusive-pill",
    Pill: "pill",
    Button: "button",
    CTA: "cta",
    Kicker: "kicker",
    Newsletter: "newsletter"
  };
  return tokenizedTypes[type] || "package";
};

export const getIconStyle = ({ size, iconIsLogo, nextToPill }) => {
  // NOTE: in rem
  let top = -0.1875;
  if (nextToPill) {
    // NOTE: For now, all pills use the same size
    // if that changes, update here
    top = -0.25;
  } else {
    switch (size) {
      case "tiny":
        top = iconIsLogo ? -0.0625 : -0.125;
        break;
      case "small":
        top = 0;
        break;
      case "medium":
        top = iconIsLogo ? 0 : 0.0625;
        break;
      default:
        top = -0.1875;
    }
  }
  return {
    position: "absolute",
    top: `${top}rem`
  };
};
// END: icons

export const listOfValidIcons = [
  "",
  "None",
  ...Object.keys(icons),
  ...Object.keys(logos)
];

export const getLabelDetailsByTypeAndPosition = ({ type, position }) => {
  switch (position) {
    case "secondary":
      switch (type) {
        case "newsletter":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "normal",
            display: "dib",
            color: "gray-darkest",
            backgroundColor: "",
            boxed: true,
            toUpperCase: true
          };
        case "kicker":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "normal",
            display: "dib",
            color: "gray-darkest",
            backgroundColor: "",
            toUpperCase: false
          };
        case "cta":
          return {
            size: "medium",
            style: "normal",
            serif: false,
            weight: "normal",
            display: "dib",
            color: "gray-dark",
            backgroundColor: "",
            toUpperCase: false
          };
        default:
          return {
            size: "tiny",
            style: "italic",
            serif: true,
            weight: "normal",
            display: "db",
            color: "gray-dark",
            backgroundColor: "",
            toUpperCase: false
          };
      }
    default:
      switch (type) {
        case "cta":
          return {
            size: "medium",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "offblack",
            backgroundColor: "",
            allowArrow: true,
            toUpperCase: false
          };
        case "exclusive-pill":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "white",
            backgroundColor: "offblack",
            innerIcon: "WPLogo",
            allowArrow: false,
            toUpperCase: true
          };
        case "pill":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "white",
            backgroundColor: "red",
            allowArrow: false,
            toUpperCase: true
          };
        case "button":
          return {
            size: "medium",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "white",
            backgroundColor: "offblack",
            allowArrow: false,
            toUpperCase: false
          };
        case "live-updates":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "red",
            backgroundColor: "",
            allowArrow: true,
            arrowIcon: "Chevron",
            toUpperCase: true
          };
        case "newsletter":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "offblack",
            backgroundColor: "",
            allowArrow: false,
            boxed: true,
            toUpperCase: true
          };
        case "kicker":
          return {
            size: "tiny",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "offblack",
            backgroundColor: "",
            allowArrow: false,
            toUpperCase: false
          };
        // package, package-nested
        default:
          return {
            size: "medium",
            style: "normal",
            serif: false,
            weight: "bold",
            color: "black",
            backgroundColor: "",
            allowArrow: true,
            arrowIcon: "Chevron",
            toUpperCase: true
          };
      }
  }
};

export const isTransparencyLabel = (label) => !!label && !/^News$/i.test(label);

export const isTransparencyLabelPrefixedToHeadline = ({ overrides }) => {
  const { headlineHide, headlinePrefixShow } = overrides;
  return !headlineHide && !!headlinePrefixShow;
};

const isLabelPlaceholder = (label = "") =>
  label.toLowerCase() === LABEL_PLACEHOLDER_VALUE.toLowerCase();

const isLabelSecondaryPlaceholder = (label = "") =>
  label.toLowerCase() === LABEL_SECONDARY_PLACEHOLDER_VALUE.toLowerCase();

/**
 * Grooms the compoundLabel generated by getCompoundLabel() for jsonapp outputType. The overall
 * goal is to alter the object as little as possible. Some values get kebab-cased (position,
 * alignment, icon) some get upper-cased if appropriate, some removed entirely ('details' object,
 * 'show' flags)
 *
 * @param {object} compoundLabel - The compoundLabel to groom
 * @return {object} - The processed compoundLabel
 */
export const groomCompoundLabelForJsonApp = ({ compoundLabel }) => {
  if (compoundLabel && compoundLabel.label && compoundLabel.label.show) {
    const upperCaseLabelText = ({ label }) => {
      if (label && label.text && label.details && label.details.toUpperCase) {
        label.text = label.text.toUpperCase();
      }
      return label;
    };

    const kebabCaseEnumValues = () => {
      if (compoundLabel) {
        if (compoundLabel.alignment)
          compoundLabel.alignment = kebabcase(compoundLabel.alignment);
        if (compoundLabel.position)
          compoundLabel.position = kebabcase(compoundLabel.position);
        if (compoundLabel.icon)
          compoundLabel.icon = kebabcase(compoundLabel.icon);
      }
      return compoundLabel;
    };

    const scrubOutUnwantedData = ({ label }) => {
      if (label) {
        // scrub out path, editablePropertyInfo, details, show
        delete label.path;
        delete label.editablePropertyInfo;
        delete label.details;
        delete label.show;
      }
      return label;
    };

    if (compoundLabel.type !== "cta") {
      delete compoundLabel.showArrow;
    }
    // START: upperCaseLabelText if appropriate
    compoundLabel.label = upperCaseLabelText({ label: compoundLabel.label });
    compoundLabel.labelSecondary = upperCaseLabelText({
      label: compoundLabel.labelSecondary
    });
    // END: upperCaseLabelText if appropriate

    // START: send legacy name for election icon
    compoundLabel.icon =
      compoundLabel.icon && jsonLabelIcons[compoundLabel.icon]
        ? jsonLabelIcons[compoundLabel.icon]
        : compoundLabel.icon;
    // START: end legacy name for election icon

    // START: kebabCaseEnumValues
    compoundLabel = kebabCaseEnumValues({ compoundLabel });
    // START: kebabCaseEnumValues

    // START: scrub out details from label
    compoundLabel.label = scrubOutUnwantedData({ label: compoundLabel.label });
    compoundLabel.labelSecondary = scrubOutUnwantedData({
      label: compoundLabel.labelSecondary
    });
    // END: scrub out details from label

    if (!compoundLabel?.showArrow) delete compoundLabel.showArrow;

    const deleteUndefinedValues = (label) => {
      if (label) {
        if (typeof label === "object") {
          Object.keys(label).forEach((key) => {
            if (label[key] === undefined) delete label[key];
            deleteUndefinedValues(label[key]);
          });
        }
      }
      return label;
    };
    compoundLabel = deleteUndefinedValues(compoundLabel);
  }

  if (compoundLabel?.form)
    compoundLabel.form = groomFormForJsonApp({ form: compoundLabel.form });

  return compoundLabel;
};

export const getCompoundLabel = ({
  content,
  overrides = {},
  editableContentObj = {},
  isAdmin
}) => {
  /* NOTE: END GOAL IS
    {
      type: "string:cta|exclusive-pill|live-updates|package|package-nested|pill|button|kicker|newsletter",
      position: "string:Default|Above Headline",
      alignment: "string:left|center",
      icon: "string:None|Headphones|Camera|Election Icon|Olympics|Play|World Cup",
      showArrow: boolean,
      label: {
        show: boolean,
        text: "sting",
        details: {
          size: "string:tiny|small|medium|normal|large|x-large",
          style: "string:normal|italic",
          serif: boolean,
          weight: "string:normal|bold",
          color: "string:offblack|gray-darkest|gray-dark|white",
          backgroundColor: "string:"none"|red",
          toUpperCase: boolean
        }
      },
      labelSecondary: {
        show: boolean,
        text: "string",
        details: {
          size: "string:tiny|small|medium|normal|large|x-large",
          style: "string:normal|italic",
          serif: boolean,
          weight: "string:normal|bold",
          color: "string:offblack|gray-darkest|gray-dark|white",
          backgroundColor: "string:none|red",
          toUpperCase: boolean
        }
      },
      link: {
        url: "sting:url",
        type: "unknown",
      }
    }
  */

  const {
    editableField = () => {
      return {};
    }
  } = editableContentObj;

  const {
    show,
    position,
    icon,
    namespace = ""
  } = fetchProps({
    data: content,
    keys: {
      show: ["", "labelShow"],
      position: ["", "labelPosition"],
      icon: ["", "labelIcon"],
      namespace: ["", "labelNameSpace"]
    },
    overrides
  });

  // NOTE: If label not due to show, return undefined
  if (!show) return undefined;

  // eslint-disable-next-line prefer-const
  let { alignment, type, url, allowDuplicateOfHeadlinePrefix } = fetchProps({
    data: content,
    keys: {
      alignment: ["", "labelAlignment"],
      type: ["", "labelType"],
      url: ["", "labelUrl"],
      // NOTE: Needed only for immersion carousel
      allowDuplicateOfHeadlinePrefix: [
        "",
        "labelAllowDuplicateOfHeadlinePrefix"
      ]
    },
    overrides
  });
  alignment =
    alignment === "inherit" && overrides.textAlignment
      ? overrides.textAlignment
      : alignment;
  type = tokenizeLabelType(type);

  const fullLink = get(getLink({ content, overrides }), "link", undefined);

  let link;
  if (!overrides.labelLinkRemove) {
    if (url) {
      link = {
        url,
        type: "unknown"
      };
    } else if (fullLink?.url) {
      link = fullLink;
    }
  }

  const label = fetchProps({
    data: content,
    keys: {
      show: ["", "labelShow"],
      text: ["label.transparency.text", "label"]
    },
    overrides
  });
  label.path = "label";

  const transparencyLabel = get(content, "label.transparency.text");

  if (
    (!label.text || isLabelPlaceholder(label.text)) &&
    isTransparencyLabel(transparencyLabel)
  ) {
    label.text = transparencyLabel;
  }

  if (
    !label.text ||
    (label.text === transparencyLabel &&
      (/^News$/i.test(transparencyLabel) ||
        (!allowDuplicateOfHeadlinePrefix &&
          isTransparencyLabelPrefixedToHeadline({ overrides }))))
  ) {
    label.text = LABEL_PLACEHOLDER_VALUE;
  }

  // NOTE: If no label, and label_display is 'Exclusive', make that the label
  // and update the label type
  if (!label.text || isLabelPlaceholder(label.text)) {
    const labelDisplay = get(content, "label_display.basic.text", undefined);
    if (/^Exclusive/i.test(labelDisplay)) {
      label.text = labelDisplay;
      type = tokenizeLabelType("Exclusive Pill");
    }
  }

  label.details = getLabelDetailsByTypeAndPosition({
    type,
    position: "primary"
  });

  const labelSecondary = fetchProps({
    data: content,
    keys: {
      show: ["", "labelSecondaryShow"],
      text: ["", "labelSecondary"]
    },
    overrides
  });
  labelSecondary.path = "labelSecondary";
  labelSecondary.details = getLabelDetailsByTypeAndPosition({
    type,
    position: "secondary"
  });

  if (
    (!labelSecondary.text || isLabelPlaceholder(labelSecondary.text)) &&
    isTransparencyLabel(transparencyLabel)
  ) {
    labelSecondary.text = transparencyLabel;
  }

  if (
    !labelSecondary.text ||
    (labelSecondary.text === transparencyLabel &&
      (label.text === transparencyLabel ||
        /^News$/i.test(transparencyLabel) ||
        (!allowDuplicateOfHeadlinePrefix &&
          isTransparencyLabelPrefixedToHeadline({ overrides }))))
  ) {
    labelSecondary.text = LABEL_SECONDARY_PLACEHOLDER_VALUE;
  }

  if (isAdmin) {
    label.editableContentInfo = {
      placeholderValue: LABEL_PLACEHOLDER_VALUE
    };

    labelSecondary.editableContentInfo = {
      placeholderValue: LABEL_SECONDARY_PLACEHOLDER_VALUE
    };

    const editableFields = {
      label: namespace ? `${namespace}Label` : "label",
      labelSecondary: namespace
        ? `${namespace}LabelSecondary`
        : "labelSecondary"
    };

    label.editableContentInfo = {
      attrs: {
        ...editableField(editableFields.label),
        suppressContentEditableWarning: true
      },
      placeholderValue: LABEL_PLACEHOLDER_VALUE
    };

    labelSecondary.editableContentInfo = {
      attrs: {
        ...editableField(editableFields.labelSecondary),
        suppressContentEditableWarning: true
      },
      placeholderValue: LABEL_SECONDARY_PLACEHOLDER_VALUE
    };

    // START: Generate toolbar data
    if (label) {
      label.editablePropertyInfo = getLabelToolbarItems({
        isAdmin,
        path: editableFields.label,
        menuItems: getPrimaryLabelMenuItems(overrides, namespace)
      });
      // NOTE: Allows any value but the transparency label value itself.
      // This saves space by not storing a value in custom fields that
      // would be used anyway
      label.editablePropertyInfo.validate = (_) =>
        !isLabelPlaceholder(_) && _ !== transparencyLabel;
    }
    if (labelSecondary) {
      labelSecondary.editablePropertyInfo = getLabelToolbarItems({
        isAdmin,
        path: editableFields.labelSecondary,
        menuItems: getPrimaryLabelMenuItems(overrides, namespace)
      });
    }
    // END: Generate toolbar data
  }

  // Handle default text by label type
  if (show && (!label.text || isLabelPlaceholder(label.text))) {
    switch (type) {
      case "live-updates":
        label.text = "Live";
        break;
      case "exclusive-pill":
        label.text = "Exclusive";
        break;
      default:
        label.text = "";
    }
  }
  // NOTE: If no label text, suppress the whole darn thing
  if (!isAdmin && (!label.text || isLabelPlaceholder(label.text)))
    return undefined;

  if (isAdmin) {
    label.text = !label.text
      ? label.editableContentInfo.placeholderValue
      : label.text;
    labelSecondary.text = !labelSecondary.text
      ? labelSecondary.editableContentInfo.placeholderValue
      : labelSecondary.text;
  } else {
    labelSecondary.text =
      !labelSecondary.text || isLabelSecondaryPlaceholder(labelSecondary.text)
        ? ""
        : labelSecondary.text;
  }

  if (isHTMLFragment(label.text)) {
    label.mime = "text/html";
  }
  if (isHTMLFragment(labelSecondary.text)) {
    labelSecondary.mime = "text/html";
  }

  const showArrow = !!link && label.details.allowArrow;

  // NOTE: The code being down here in part means that there cannot
  // be a search form w/o a label.
  // NOTE: To limit to particular label types use code like this:
  // const form = /^(package(-nested)?)$/.test(type) ? getForm({ overrides, isAdmin }) : undefined;
  const form = getForm({ overrides, isAdmin });

  return {
    type,
    position,
    alignment,
    icon,
    showArrow,
    label,
    labelSecondary: labelSecondary.show ? labelSecondary : undefined,
    link,
    form
  };
};
