// NOTE: In the key/value pairs below, if you want to change
// what the editor sees, change the value and leave the key alone.

/**
 * Art Positions
 * @type {String[]}
 */
export const artPositions = {
  "Art above head": "Art above head",
  "Art below head": "Art below head",
  "Art below byline": "Art below byline",
  "Art left": "Art left",
  "Art left of head": "Art left of head",
  "Art left of blurb": "Art left of blurb",
  "Art right": "Art right",
  "Art right of head": "Art right of head",
  "Art right of blurb": "Art right of blurb"
};

/**
 * Art Sizes
 * @type {String[]}
 */
export const artSizes = {
  "Full Width": "Full Width",
  "XX-Large": "XX-Large",
  "X-Large": "X-Large",
  Large: "Large",
  Medium: "Medium",
  Small: "Small",
  "X-Small": "X-Small",
  Tiny: "Tiny",
  Mini: "Mini"
};

/**
 * Image Fallbacks
 * @type {String[]}
 */
export const artImageFallbacks = {
  default: "Default behavior",
  bright: "Bright",
  "bright-to-default": "Bright -> Default",
  headshot: "Headshot",
  "headshot-to-default": "Headshot -> Default"
};

/**
 * Make it round options
 * @type {String[]}
 */
export const artMakeItRoundOptions = {
  never: "Never",
  "if-headshot": "If headshot",
  always: "Always"
};

/**
 * Device options
 * @type {String[]}
 */
export const deviceOptions = {
  touch: "Touch devices only (default)",
  all: "All devices"
};

/**
 * Max loops options
 * @type {String[]}
 */
export const maxLoopsOptions = {
  100: "Infinte",
  1: "Once"
};

/**
 * Scaling strategies
 * @type {String[]}
 */
export const scalingStrategies = {
  "aspect-fill": "Fill (default)",
  "aspect-fit": "Fit"
};

/**
 * Headline Sizes
 * @type {String[]}
 */
export const headlineSizes = {
  Tiny: "15px - Tiny",
  "Extra small": "18px - Extra small",
  Small: "20px - Small",
  Medium: "24px - Medium",
  Large: "32px - Large",
  "Extra large": "40px - Extra large",
  Huge: "56px - Huge",
  Massive: "72px - Massive",
  Colossal: "94px - Colossal",
  "COLOSSAL (ALL CAPS)": "94px - COLOSSAL (ALL CAPS)",
  Jumbo: "115px - Jumbo",
  "JUMBO (ALL CAPS)": "115px - JUMBO (ALL CAPS)",
  Gargantuan: "141px - Gargantuan",
  "GARGANTUAN (ALL CAPS)": "141px - GARGANTUAN (ALL CAPS)"
};

/**
 * Image Aspect Ratios
 * @type {String[]}
 */
export const aspectRatios = {
  "16:9": "16:9",
  "3:2": "3:2",
  "4:3": "4:3",
  "1:1": "1:1",
  "2:3": "2:3 (vertical)",
  "9:16": "9:16 (vertical)"
};

/**
 * Image Aspect Ratios with Inherit
 * @type {String[]}
 */
export const aspectRatiosMobileOnly = {
  "": "Default",
  ...aspectRatios
};

/**
 * Suppress duplicates
 * @type {String[]}
 */
export const suppressDuplicatesOptions = {
  inherit: "inherit",
  yes: "yes",
  no: "no"
};

/**
 * Discount duplicates
 * @type {String[]}
 */
export const discountDuplicatesOptions = {
  inherit: "inherit",
  yes: "yes",
  no: "no"
};

/**
 * Divider weights
 * @type {String[]}
 */
export const dividerWeights = {
  normal: "Light (default)",
  bold: "Dark",
  none: "None"
};

/**
 * Label Icons
 * @type {String[]}
 */
export const labelIcons = {
  None: "None",
  Camera: "Camera",
  Chart: "Chart",
  "Election Icon": "Election Icon",
  Headphones: "Headphones",
  Olympics: "Olympics",
  Play: "Play",
  "World Cup": "World Cup",
  "The 7": "The 7",
  "Post Pulse": "Post Pulse"
};

export const jsonLabelIcons = {
  ...labelIcons,
  "Election Icon": "Election Star",
  "Post Pulse": "Postpulse"
};

/**
 * Label types
 * @type {String[]}
 */
export const labelTypes = {
  Package: "Package - with rule",
  "Package Nested": "Package - no rule",
  Pill: "Breaking News Pill",
  "Exclusive Pill": "Exclusive Pill",
  "Live Updates": "Live Updates",
  Kicker: "Kicker"
};

/**
 * Label Positions
 * @type {String[]}
 */
export const labelPositions = {
  Default: "Top",
  "Above Headline": "Above headline"
};

/**
 * Label Alignments
 * @type {String[]}
 */
export const labelAlignments = {
  inherit: "inherit",
  left: "left",
  center: "center"
};

/**
 * Headline Styles
 * @type {String[]}
 */
export const headlineStyles = {
  Regular: "Regular",
  Bold: "Bold",
  Italic: "Italic"
};

/**
 * Headline Font Families
 * @type {String[]}
 */
export const headlineFontFamilies = {
  Default: "Default",
  Ultra: "Ultra"
};

/**
 * Headline Alignments
 * @type {String[]}
 */
export const headlineAlignments = {
  inherit: "inherit",
  left: "left",
  center: "center"
};

/**
 * Audio Allow Article Type
 * @type {String[]}
 */
export const audioAllowArticleTypes = {
  none: "Never",
  any: "If available",
  // automated: "Only if automated ",
  human: "Only if human read"
};

/**
 * Audio Positions
 * @type {String[]}
 */
export const audioPositions = {
  "Above Art": "Above Art",
  "Below Art": "Below Art",
  Default: "Default",
  "Below Related Links": "Below Related Links"
};

/**
 * Related Link Positions
 * @type {String[]}
 */
export const relatedLinksPositions = {
  Default: "Default",
  Bottom: "Bottom",
  None: "None"
};

/**
 * Related Link Sizes
 * @type {String[]}
 */
// NOTE: Keep in sync with headlineSizes above
export const relatedLinksSizes = {
  "Related links": "14px - Default",
  Small: "20px - Small"
};

/**
 * Related Link Styles
 * @type {String[]}
 */
export const relatedLinksStyles = {
  Stacked: "Stacked",
  "Side-by-side": "Side-by-side"
};

/**
 * Related Link Label Styles
 * @type {String[]}
 */
export const relatedLinksLabelTypes = Object.keys(labelTypes).reduce(
  (acc, key) => {
    if (/(Kicker|Live Updates)/.test(key)) {
      acc[key] = labelTypes[key];
    }
    return acc;
  },
  {}
);

/**
 * Live Ticker Label Styles
 * @type {String[]}
 */
export const liveTickerLabelTypes = Object.keys(labelTypes).reduce(
  (acc, key) => {
    if (/Live Updates/.test(key)) {
      acc[key] = labelTypes[key];
    }
    return acc;
  },
  {}
);

/**
 * CTA Label types
 * @type {String[]}
 */
export const ctaLabelTypes = {
  CTA: "Text (default)",
  Button: "Button"
};

/**
 * Count sizes
 * @type {String[]}
 */
export const countSizes = {
  auto: "Auto",
  large: "Large",
  medium: "Medium"
};

/**
 * Forced Bleed Types
 * @type {String[]}
 */
export const cardifyForcedBleedTypes = {
  default: "Default",
  inset: "Inset",
  "not-inset": "Full-width"
};
