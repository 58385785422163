import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";

// NOTE: forYou will not be fed into useContent. Rather it uses useApi.
// Hence it does not use PCS. See notes below
import forYou from "~/content/sources/foryou-flex-headlines";
import {
  getAssembledContentConfigValue,
  resetSelectCustomFields,
  displayNameMap,
  paramKeyMap,
  defaultContentConfig,
  getShouldUpdate
} from "./OverrideFormWidget.helpers";
import { addWww } from "../../../utilities/www-remover";

const fieldsToReset = [
  "alternateArtMobileOnly",
  "descriptionText",
  "headline",
  "headlineMobileOnly",
  "label",
  "labelSecondary"
];

const OverrideFormWidget = ({
  value,
  setEntity,
  Select,
  TextArea,
  QueriesForm,
  k: contentConfigType // flexFeatureContentConfig, liveGraphicsContentConfig, etc
}) => {
  const { contentConfigValues = {}, contentService = "" } = value || {
    contentConfigValues: defaultContentConfig,
    contentService: ""
  };
  const {
    content_path: contentPath,
    "author-slug": authorSlug,
    query,
    section,
    tag,
    limit,
    offset,
    id,
    surface
  } = contentConfigValues;
  const initialState = {
    ...defaultContentConfig,
    ...{
      contentPath,
      authorSlug,
      query,
      section,
      tag,
      limit,
      offset,
      id,
      surface
    }
  };

  const [hasMounted, setHasMounted] = useState(false);
  const [newContentService, setNewContentService] = useState(contentService);
  const [contentConfigVals, setContentConfigVals] = useState(initialState);
  const [contentConfigValueLookup, setContentConfigValueLookup] = useState(
    getAssembledContentConfigValue(contentConfigType, contentConfigVals)
  );

  const resetState = (e) => {
    const newestContentType = e.target.value;
    if (newestContentType !== newContentService) {
      const existingVals = Object.keys(contentConfigVals).reduce((acc, key) => {
        const val = contentConfigVals[key];
        if (val !== undefined && val !== null) {
          acc[key] = val;
        }
        return acc;
      }, {});
      setContentConfigVals({
        ...defaultContentConfig,
        ...existingVals
      });
      setNewContentService(newestContentType);
    }
  };

  const setQueryState = (queryState, valueState) => {
    const newContentConfigVals = { ...contentConfigVals };
    const key = paramKeyMap[queryState] || queryState;

    newContentConfigVals[key] = valueState;
    setContentConfigVals(newContentConfigVals);
  };

  const updateContentType = (
    newContentConfigValues = contentConfigValueLookup[newContentService]
  ) => {
    setEntity((entity = {}) => {
      const noCustomFields = !get(entity, "props.customFields");
      const noContentConfig = !get(
        entity,
        `props.customFields.${contentConfigType}`
      );

      // We need this here for variant editing
      if (noCustomFields || noContentConfig) {
        const newValues = {
          contentService: newContentService,
          contentConfigValues: newContentConfigValues
        };
        // Automerge doesn't allow the {...prev, field: value } paradigm
        // it requires mutating the fields of an existing object whenever possible.
        if (!entity.props) {
          entity.props = { customFields: { [contentConfigType]: newValues } };
        } else if (noCustomFields) {
          entity.props.customFields = { [contentConfigType]: newValues };
        } else if (noContentConfig) {
          entity.props.customFields[contentConfigType] = newValues;
        }
        resetSelectCustomFields(entity, fieldsToReset);
      } else {
        const existingContentService = get(
          entity,
          `props.customFields.${contentConfigType}.contentService`
        );

        if (!existingContentService) {
          entity.props.customFields[contentConfigType] = {
            contentService: newContentService,
            contentConfigValues: newContentConfigValues
          };
        } else if (existingContentService !== newContentService) {
          entity.props.customFields[contentConfigType].contentService =
            newContentService;
        }

        const shouldUpdate =
          !existingContentService ||
          existingContentService !== newContentService ||
          getShouldUpdate(
            entity.props.customFields[contentConfigType].contentConfigValues,
            newContentConfigValues
          );

        entity.props.customFields[contentConfigType].contentConfigValues =
          newContentConfigValues;

        if (shouldUpdate) {
          resetSelectCustomFields(entity, fieldsToReset);
        }
      }
      return entity;
    });
  };

  useEffect(() => {
    // Change the entire form & fields available
    setContentConfigValueLookup(
      getAssembledContentConfigValue(contentConfigType, defaultContentConfig)
    );
  }, [contentConfigType]);

  const hasUndefined = (o) => {
    return Object.keys(o || {}).find((k) => {
      return typeof o[k] === "object"
        ? hasUndefined(o[k])
        : typeof o[k] === "undefined";
    });
  };
  useEffect(() => {
    if (hasMounted) {
      const newVals = getAssembledContentConfigValue(
        contentConfigType,
        contentConfigVals
      )[newContentService];

      if (!hasUndefined(newVals)) {
        // Update entity
        updateContentType(
          getAssembledContentConfigValue(contentConfigType, contentConfigVals)[
            newContentService
          ]
        );
      }
    } else {
      setHasMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentConfigType, newContentService, contentConfigVals]);

  const getContentConfigValueLookup = () => {
    return Object.keys(contentConfigValueLookup).reduce((acc, serviceName) => {
      Object.keys(contentConfigValueLookup[serviceName]).reduce((lacc, key) => {
        lacc[key] = contentConfigVals[paramKeyMap[key] || key] ?? lacc[key];
        return lacc;
      }, contentConfigValueLookup[serviceName]);
      return acc;
    }, contentConfigValueLookup);
  };

  if (!value) return null;

  return (
    <form key={contentConfigType} aria-label="Content service form">
      <Select
        onChange={resetState}
        labels={displayNameMap}
        options={Object.keys(contentConfigValueLookup).map((service) => ({
          name: service,
          value: service
        }))}
        value={newContentService}
        label="Content Service"
      />
      <div className="mt-md mb-md" />
      {newContentService === "prism-promo" ? (
        <TextArea
          key="content-path"
          type="text"
          label="Content Path"
          onChange={(e) => {
            setQueryState("contentPath", e.target.value);
          }}
          value={addWww(contentConfigVals.contentPath || contentPath)}
        />
      ) : (
        <QueriesForm
          key={newContentService}
          type="text"
          onChange={setQueryState}
          lookup={getContentConfigValueLookup()}
          contentService={newContentService}
        />
      )}
    </form>
  );
};

OverrideFormWidget.propTypes = {
  value: PropTypes.object,
  setEntity: PropTypes.func,
  Select: PropTypes.func,
  TextArea: PropTypes.func,
  QueriesForm: PropTypes.func,
  k: PropTypes.string
};

export default OverrideFormWidget;

/*
 * NOTE: There is currently only one client-side, non-PCS content source: foryou-flex-headlines. Things to keep in mind:
 *
 *   1. This client-side strategy is needed b/c the For You content is based on user-specific data. The server-side
 *      does not have user-specific data (at the time we shard the CDN-cache per user -- which is highly unlikely --
 *      then this would no longer be a concern).
 *
 *   2. The non-PCS strategy is needed for similar reasons except applied to PCS. We don't want to create a cache
 *      entry in PCS for each user which could crush the PCS database. Nor do we want to use PCS for uncached content.
 *      PCS would potenitally experience a lot of stress processing a request for each user.
 *
 *      THERFORE:
 *
 *   3. The for-you-flex-headlnes content source exists IN THIS REPO (not in PCS) and is used to generate the
 *      client-side endpoint. That config has params and resolve and transform functions which are used by
 *      getClientSideConfig()
 *
 *   4. The resolve generates an endpoint (and headers and body, etc. as appropriate) that get passed to useApi().
 *
 *   3. If there were a coldstart foryou endpoint not based on any user-specific data, that could go into into PCS.
 *      Before for you flex, for you "classic" did have a coldstart endpoint and isServerSideOk() returned true
 *      if isAdmin and outputType === jsonapp. At the time, we asked that the newsroom to hide the content from jsonapp
 *      using the tools in their control as desired.
 *
 *   4. It powers the backing content of the flex feature, i.e. anciallary content does not handle server side
 *      content as the code new stands.
 *
 *   5. It is content of type "feed".
 *
 *   There are any number of consquences from all this, but some important ones are that IF another client side content
 *   source is needed that does not have a PCS stand-in OR if the client-side content source has its own fetch method
 *   OR is needed for ancillary content OR is not of type "feed",
 *   THEN a number of changes would need to be introduced to cleanly integrate it.
 */
export const CLIENT_SIDE_CONTENT_SOURCES = {
  "foryou-flex-headlines": {
    checkIsReady: ({ bp }) =>
      typeof global.window !== "undefined" && /^..$/i.test(bp),
    isServerSideOk: () => false,
    ...forYou
  }
};
