import PropTypes from "prop-types";

export const LiveAlertPropTypes = {
  dismiss: PropTypes.func,
  children: PropTypes.any,
  id: PropTypes.string,
  setEntity: PropTypes.func,
  value: PropTypes.string,
  customFields: PropTypes.shape({
    prefix: PropTypes.richtext.tag({
      defaultValue: "BREAKING: ",
      name: "Prefix",
      description: 'Enter prefix text e.g. "BREAKING: ".'
    }),
    content: PropTypes.richtext.tag({
      name: "Content",
      defaultValue: ""
    }),
    url: PropTypes.url.tag({ name: "Url" })
  })
};
