import PropTypes from "prop-types";
import defaults from "../components/features/fronts/flex-feature/utilities/custom-field-defaults";
import mobilePresets from "../components/features/fronts/flex-feature/utilities/mobile-presets";
import { captionOptions } from "~/shared-components/story-card/_children/Caption.helpers";
import {
  artPositions,
  artSizes,
  artImageFallbacks,
  artMakeItRoundOptions,
  deviceOptions,
  suppressDuplicatesOptions,
  discountDuplicatesOptions,
  maxLoopsOptions,
  scalingStrategies,
  headlineSizes,
  aspectRatios,
  aspectRatiosMobileOnly,
  labelIcons,
  labelTypes,
  labelPositions,
  labelAlignments,
  headlineStyles,
  headlineFontFamilies,
  headlineAlignments,
  dividerWeights,
  audioAllowArticleTypes,
  audioPositions,
  relatedLinksPositions,
  relatedLinksSizes,
  relatedLinksStyles,
  relatedLinksLabelTypes,
  liveTickerLabelTypes,
  ctaLabelTypes,
  countSizes,
  cardifyForcedBleedTypes
} from "../components/features/fronts/utilities/common-front-properties";

export const everythingButTheCustomFields = {
  id: PropTypes.string.isRequired,
  context: PropTypes.shape({
    isAdmin: PropTypes.bool
  }),
  children: PropTypes.node
};

export const FlexFeaturePropTypes = {
  ...everythingButTheCustomFields,
  customFields: PropTypes.shape({
    displayName: PropTypes.string.tag({
      name: "Display Name",
      defaultValue: defaults.displayName
    }),
    flexFeatureContentConfig: PropTypes.contentConfig([
      "story-card",
      "story-card-feed"
    ]).tag({
      defaultValue: {
        contentService: "prism-promo",
        contentConfigValues: {
          content_path: ""
        }
      },
      name: "Content Config"
    }),
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      description:
        "If there is too much white space use this to adjust row height",
      defaultValue: defaults.rowSpan
    }),
    suppressDuplicates: PropTypes.oneOf(
      Object.keys(suppressDuplicatesOptions)
    ).tag({
      labels: suppressDuplicatesOptions,
      group: "DUPLICATES",
      name: "Suppress duplicates",
      description:
        "Select “Yes” to remove content from this feature that duplicates content displayed higher on the page. “No” keeps duplicates, while “Inherit” applies the chain-level setting. Default is “Inherit.”",
      defaultValue: defaults.suppressDuplicates,
      hidden: false
    }),
    discountDuplicates: PropTypes.oneOf(
      Object.keys(discountDuplicatesOptions)
    ).tag({
      labels: discountDuplicatesOptions,
      group: "DUPLICATES",
      name: "Ignore in duplication check",
      description:
        "Select “Yes” to exclude this feature’s content from duplicate detection, preventing it from affecting duplicate removal elsewhere. “No” includes it in checks, and “Inherit” uses the chain-level default. Default setting is “Inherit.”",
      defaultValue: defaults.discountDuplicates,
      hidden: false
    }),

    lastPickedPreset: PropTypes.string.tag({
      // group: "PRESETS",
      name: "Desktop preset (last one picked)",
      description:
        "This field contains the value of the last preset chosen. WARNING: Customizations may cause this value to not reflect the actual state of the feature. You could modify this field to reflect that (i.e. type in 'Feed head (but Pill label)')",
      defaultValue: defaults.lastPickedPreset,
      hidden: true
    }),
    lastPickedMiniPreset: PropTypes.string.tag({
      // group: "PRESETS",
      name: "Mini preset (last one picked)",
      description:
        "This field contains the value of the last mini preset chosen.",
      defaultValue: defaults.lastPickedMiniPreset,
      hidden: true
    }),
    mobilePreset: PropTypes.oneOf(Object.keys(mobilePresets)).tag({
      // group: "PRESETS",
      name: "Mobile preset",
      description: "Select the mobile rendering of this feature.",
      defaultValue: defaults.mobilePreset,
      hidden: true
    }),

    // START: OVERALL STYLES
    textAlignment: PropTypes.oneOf(
      Object.keys({ left: "left", center: "center" })
    ).tag({
      group: "OVERALL STYLES",
      name: "Text horizontal alignment",
      description: "Select the horizontal text alignment.",
      defaultValue: defaults.textAlignment
    }),
    textVerticalAlignment: PropTypes.oneOf(
      Object.keys({ top: "top", center: "center" })
    ).tag({
      group: "OVERALL STYLES",
      name: "Text vertical alignment",
      description:
        "Select the vertical text alignment. Use 'top' alignment except for rare stories that are considered to be special features.",
      defaultValue: defaults.textVerticalAlignment
    }),
    // Logical or fallsbacks to be removed after Fusion field names are updated.
    horizontalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      group: "OVERALL STYLES",
      name: "Horizontal divider",
      description:
        "Select the weight of the horizontal divider, which appears below the feature when there is another feature positioned below it",
      defaultValue: defaults.horizontalDividerWeight
    }),
    verticalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      group: "OVERALL STYLES",
      name: "Vertical divider",
      description:
        "Select the weight of the vertical divider, which appears to the left of the feature when there is another feature to the left of it",
      defaultValue: defaults.verticalDividerWeight,
      hidden: true
    }),
    hideOnDesktop: PropTypes.bool.tag({
      group: "OVERALL STYLES",
      name: "Hide feature on desktop",
      description:
        "Check this to hide this feature at desktop breakpoints (exact breakpoints depend on chain layout).",
      defaultValue: defaults.hideOnDesktop
    }),

    // START: LABEL
    labelShow: PropTypes.bool.tag({
      group: "LABEL",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      defaultValue: defaults.labelShow
    }),
    labelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      group: "LABEL",
      name: "Type",
      description: "Select the look of the the label.",
      defaultValue: defaults.labelType
    }),
    labelPosition: PropTypes.oneOf(Object.keys(labelPositions)).tag({
      labels: labelPositions,
      group: "LABEL",
      name: "Position",
      description: "Select the position of the the label.",
      defaultValue: defaults.labelPosition
    }),
    label: PropTypes.string.tag({
      group: "LABEL",
      name: "Label text",
      description: "The label text. Optional.",
      defaultValue: defaults.label
    }),
    labelLinkRemove: PropTypes.bool.tag({
      group: "LABEL",
      name: "Remove label link",
      description: "Removes custom or default link.",
      defaultValue: defaults.labelLinkRemove,
      hidden: true
    }),
    labelUrl: PropTypes.string.tag({
      group: "LABEL",
      name: "Label link override",
      description: "The label link. Default value matches headline link.",
      defaultValue: defaults.labelUrl
    }),
    labelSecondaryShow: PropTypes.bool.tag({
      group: "LABEL",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      defaultValue: defaults.labelSecondaryShow
    }),
    labelSecondary: PropTypes.string.tag({
      group: "LABEL",
      name: "Secondary label",
      description: "The secondary label text. Optional.",
      defaultValue: defaults.labelSecondary
    }),
    labelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      group: "LABEL",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'.",
      defaultValue: defaults.labelAlignment
    }),
    labelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      group: "LABEL",
      name: "Select icon to prepend",
      description:
        "The label icon to prepend to the label. Defaults to 'None'.",
      defaultValue: defaults.labelIcon
    }),

    // START: ART
    alternateArt: PropTypes.richtext.tag({
      group: "ART",
      name: "Alternate Art",
      description:
        "Enter the URL of a photo, animated GIF (only works at 'Native' aspect ratio), video, graphic, gallery or html fragment to override the default art accompanying this story. When a video is the main content and the art position (see below) is full-width (e.g. 'Above headline', 'Below headline', etc.), then by default, the art spot will by default become an inline player.",
      defaultValue: defaults.alternateArt,
      canPasteHtml: true
    }),
    alternateArtMobileOnly: PropTypes.string.tag({
      group: "ART",
      name: "Mobile Image",
      description:
        "Enter the URL of a photo for mobile. NOTE: Video, Custom HTML, HTML Fragment, etc. not supported.",
      defaultValue: defaults.alternateArtMobileOnly
    }),
    inlineVideo: PropTypes.bool.tag({
      group: "ART",
      name: "If video, play inline",
      description: "Check this to make the video playable inline.",
      defaultValue: defaults.inlineVideo
    }),
    coverArtUrl: PropTypes.richtext.tag({
      group: "ART",
      name: "Cover art URL",
      description:
        "Enter the URL of a photo to override the cover art if the alternate art is a video or custom html.",
      defaultValue: defaults.coverArtUrl
    }),
    coverArtCaption: PropTypes.richtext.tag({
      group: "ART",
      name: "Cover art caption",
      description: "Cover art caption.",
      defaultValue: defaults.coverArtCaption
    }),
    coverArtAltText: PropTypes.richtext.tag({
      group: "ART",
      name: "Cover art alt text",
      description: "Cover art alternate text for screen readers.",
      defaultValue: defaults.coverArtAltText
    }),
    coverArtIsLive: PropTypes.bool.tag({
      group: "ART",
      name: "Is cover art a live image",
      description: "Selecting this tells the app not to cache the image.",
      defaultValue: defaults.coverArtIsLive
    }),
    artOverlayShow: PropTypes.boolean.tag({
      name: "Show art overlay button",
      group: "ART",
      defaultValue: defaults.artOverlayShow
    }),
    artOverlayText: PropTypes.richtext.tag({
      name: "Art overlay text",
      group: "ART",
      description:
        "Enter text to appear in an overlay on the art accompanying this story. This does not apply to inline videos or slideshows.",
      defaultValue: defaults.artOverlayText
    }),
    includeDurationOrLive: PropTypes.bool.tag({
      group: "ART",
      name: "Include video duration or LIVE in overlay text? LIVE will only appear for live videos if art overlay text is empty.",
      description:
        "Check this to append the video duration to the art overlay text.",
      defaultValue: defaults.includeDurationOrLive
    }),
    alternateArtLinkEnable: PropTypes.bool.tag({
      group: "ART",
      name: "Enable 'Alternate LINK for the art'",
      description:
        "Check this to use the 'Alternate LINK for the art'. Otherwise, it will be ignored.",
      defaultValue: defaults.alternateArtLinkEnable
    }),
    alternateArtLink: PropTypes.string.tag({
      group: "ART",
      name: "Alternate LINK for the art",
      description:
        "Enter the URL of the page you want the alternate art to link to if you don't want it to link to the main content of this feature. This does not apply to inline videos.",
      defaultValue: defaults.alternateArtLink
    }),
    coverArtWidth: PropTypes.number.tag({
      group: "ART",
      name: "Advanced: Cover art width (pixels)",
      description:
        "Enter the width of the cover art in pixels. Best kept blank for everything except CustomHTML.",
      defaultValue: defaults.coverArtWidth
    }),
    coverArtHeight: PropTypes.number.tag({
      group: "ART",
      name: "Advanced: Cover art height (pixels)",
      description:
        "Enter the height of the cover art in pixels. Best kept blank for everything except CustomHTML.",
      defaultValue: defaults.coverArtHeight
    }),
    artImageFallback: PropTypes.oneOf(Object.keys(artImageFallbacks)).tag({
      labels: artImageFallbacks,
      group: "ART",
      name: "Fallback image",
      description: "Select fallback image behavior.",
      defaultValue: defaults.artImageFallbacks
    }),

    // START: ART LAYOUT
    artHide: PropTypes.bool.tag({
      group: "ART LAYOUT",
      name: "Hide Art?",
      description:
        "If checked, hide anything in the 'Art' slot (default un-checked).",
      defaultValue: defaults.artHide
    }),
    artPosition: PropTypes.oneOf(Object.keys(artPositions)).tag({
      group: "ART LAYOUT",
      name: "Art Position",
      description: "Choose how you would like your card(s) to be displayed.",
      defaultValue: defaults.artPosition
    }),
    artSize: PropTypes.oneOf(Object.keys(artSizes)).tag({
      labels: artSizes,
      group: "ART LAYOUT",
      name: "Art Size",
      description:
        "Determines the size of the art (N/A for layouts Art Above/Below Head). ",
      defaultValue: defaults.artSize
    }),
    artAspectRatio: PropTypes.oneOf(Object.keys(aspectRatios)).tag({
      labels: aspectRatios,
      name: "Image Aspect Ratio",
      group: "ART LAYOUT",
      description: "The aspect ratio for the image that is displayed",
      defaultValue: defaults.artAspectRatio
    }),
    artAspectRatioMobileOnly: PropTypes.oneOf(
      Object.keys(aspectRatiosMobileOnly)
    ).tag({
      labels: aspectRatiosMobileOnly,
      name: "Image Aspect Ratio (Mobile)",
      group: "ART LAYOUT",
      description:
        "The aspect ratio for the image that is displayed at mobile. The 'Default' value indicates to use the aspect ratio associated with this feature's mobile preset and lacking that, inherit the desktop value",
      defaultValue: defaults.artAspectRatioMobileOnly
    }),
    wrapText: PropTypes.bool.tag({
      group: "ART LAYOUT",
      name: "Wrap text around art?",
      description:
        "Check this to make the text (headline, blurb, etc.) wrap around the art slot. Only applies when art has one of the right positions. It is automatically false for the left positions.",
      defaultValue: defaults.wrapText
    }),
    artUseHiRes: PropTypes.bool.tag({
      name: "Use high-resolution image(s)",
      group: "ART LAYOUT",
      description:
        "Check this to render the image in hi res. This can be useful for graphics and illustrations",
      defaultValue: defaults.artUseHiRes
    }),
    cardifyForcedBleedType: PropTypes.oneOf(
      Object.keys(cardifyForcedBleedTypes)
    ).tag({
      labels: cardifyForcedBleedTypes,
      name: "Force bleed type on Cardify",
      group: "ART LAYOUT",
      description:
        'Use sparingly. Best use case: Set to "Inset" when alt art or mobile-only art is a graphic or illustration that has text on it which will force the art slot to have the same width as the editorial content.',
      defaultValue: defaults.cardifyForcedBleedType
    }),
    artMakeItRound: PropTypes.oneOf(Object.keys(artMakeItRoundOptions)).tag({
      labels: artMakeItRoundOptions,
      name: "Make 1:1 image round",
      group: "ART LAYOUT",
      description:
        "Select when to make the image round (works only when aspect ratio is 1:1)",
      defaultValue: defaults.artMakeItRound
    }),

    // START: SLIDESHOW
    slideshowShow: PropTypes.bool.tag({
      group: "SLIDESHOW",
      name: "Show slideshow",
      description: "Check this to enable the slideshow",
      defaultValue: defaults.slideshowShow
    }),
    slideshowUrls: PropTypes.richtext.tag({
      group: "SLIDESHOW",
      name: "Image urls (max of 10)",
      description:
        "Enter image urls separated by a return or space character(s). Max of 10 items will be shown.",
      defaultValue: defaults.slideshowUrls
    }),
    slideshowAutoplay: PropTypes.bool.tag({
      group: "SLIDESHOW",
      name: "Autoplay",
      description: "Check this to autoplay.",
      defaultValue: defaults.slideshowAutoplay
    }),
    slideshowSlideDuration: PropTypes.number.tag({
      group: "SLIDESHOW",
      name: "Time per image (seconds)",
      description:
        "The time a photo appears before it fades into the next one.",
      defaultValue: defaults.slideshowSlideDuration
    }),
    slideshowTransitionDuration: PropTypes.number.tag({
      group: "SLIDESHOW",
      name: "Fade speed (seconds)",
      description: "The transition time of the fade between photos.",
      defaultValue: defaults.slideshowTransitionDuration
    }),
    slideshowMaxLoops: PropTypes.oneOf(Object.keys(maxLoopsOptions)).tag({
      labels: maxLoopsOptions,
      group: "SLIDESHOW",
      name: "Autoplay loop",
      description: "Select the max number of loops. Infinite is just 100",
      defaultValue: defaults.slideshowMaxLoops
    }),
    slideshowDots: PropTypes.oneOf(Object.keys(deviceOptions)).tag({
      labels: deviceOptions,
      group: "SLIDESHOW",
      name: "Pagination dots",
      description: "Choose the better option for your needs.",
      defaultValue: defaults.slideshowDots
    }),
    slideshowScalingStrategy: PropTypes.oneOf(
      Object.keys(scalingStrategies)
    ).tag({
      labels: scalingStrategies,
      group: "SLIDESHOW",
      name: "Scaling strategy",
      description: "Choose the better option for your needs.",
      defaultValue: defaults.slideshowScalingStrategy
    }),
    slideshowUseSameCaption: PropTypes.bool.tag({
      group: "SLIDESHOW",
      name: "Use same caption?",
      description: "Check this to use the same caption for all images.",
      defaultValue: defaults.slideshowUseSameCaption
    }),

    // START: CAPTION
    captionContent: PropTypes.oneOf(captionOptions).tag({
      name: "How much caption to show",
      group: "CAPTION",
      description: "Select how much caption you want to show under the art",
      defaultValue: defaults.captionContent
    }),

    // START: VIDEO
    allowAnimation: PropTypes.bool.tag({
      name: "Allow animation if available",
      group: "VIDEO",
      description:
        "Allow animation if available. The animation will override cover art on platforms that support it.",
      defaultValue: defaults.allowAnimation
    }),
    autoplay: PropTypes.bool.tag({
      name: "Autoplay",
      group: "VIDEO",
      description:
        "Check this and the video will auto play. In the admin, autoplay is suppressed except for looping videos, where this can be used to see what the transition at the end of the loop looks like.",
      defaultValue: defaults.autoplay
    }),
    muted: PropTypes.bool.tag({
      name: "Start muted",
      group: "VIDEO",
      description: "Check this and the video will start muted.",
      defaultValue: defaults.muted
    }),
    ads: PropTypes.bool.tag({
      name: "Allow preroll",
      group: "VIDEO",
      description: "Check this and the video is eligible for a preroll ad.",
      defaultValue: defaults.ads
    }),

    // START: HEADLINE
    headlineHide: PropTypes.bool.tag({
      group: "HEADLINE",
      name: "Hide Headline?",
      description:
        "If checked, display headline (default checked). NOTE: This configuration option may not work for all Views, such as 'Art Only.'",
      defaultValue: defaults.headlineHide
    }),
    headline: PropTypes.richtext.tag({
      group: "HEADLINE",
      name: "Headline content",
      defaultValue: defaults.headline
    }),
    headlineMobileOnly: PropTypes.richtext.tag({
      group: "HEADLINE",
      name: "Mobile Headline",
      defaultValue: defaults.headlineMobileOnly
    }),
    headlineSize: PropTypes.oneOf(Object.keys(headlineSizes)).tag({
      labels: headlineSizes,
      group: "HEADLINE",
      name: "Headline size",
      description: "Choose how large the headline should be.",
      defaultValue: defaults.headlineSize
    }),
    headlineStyle: PropTypes.oneOf(Object.keys(headlineStyles)).tag({
      group: "HEADLINE",
      name: "Headline style",
      description:
        "Choose the styles for your headline. NOTE: Tiny will not be bolded or italicized",
      defaultValue: defaults.headlineStyle
    }),
    headlineFontFamily: PropTypes.oneOf(Object.keys(headlineFontFamilies)).tag({
      group: "HEADLINE",
      name: "Headline font",
      description:
        "Choose the font for your headline. NOTE: Tiny will not be affected",
      defaultValue: defaults.headlineFontFamily
    }),
    headlineAlignment: PropTypes.oneOf(Object.keys(headlineAlignments)).tag({
      group: "HEADLINE",
      name: "Headline alignment",
      description: "Choose the alignment for your headline.",
      defaultValue: defaults.headlineAlignment
    }),
    headlinePrependBullet: PropTypes.bool.tag({
      group: "HEADLINE",
      name: "Prepend bullet to headline?",
      description: "Adds a bullet to the start of your headline.",
      defaultValue: defaults.headlinePrependBullet
    }),
    headlinePrefixShow: PropTypes.bool.tag({
      group: "HEADLINE",
      name: "Prepend transparency label?",
      description:
        "Adds the transparancy label to the start of your headline. NOTE: If the label text would be the transparency label, it will not and this option will take precedence. WARNING: Not yet supported on apps. Using this option means transparency labels will be missing on apps.",
      defaultValue: defaults.headlinePrefixShow
    }),
    deckShow: PropTypes.bool.tag({
      group: "HEADLINE",
      name: "Show Deck?",
      description:
        "If checked, display the deck (default unchecked). NOTE: Only shows if headlne shows.",
      defaultValue: defaults.deckShow
    }),
    deck: PropTypes.richtext.tag({
      group: "HEADLINE",
      name: "Deck text",
      defaultValue: ""
    }),

    // START: excerpt
    excerptHide: PropTypes.bool.tag({
      group: "EXCERPT",
      name: "Hide excerpt",
      defaultValue: defaults.excerptHide,
      hidden: true
    }),
    excerptText: PropTypes.string.tag({
      group: "EXCERPT",
      name: "Excerpt text",
      defaultValue: defaults.excerptText,
      hidden: true
    }),

    // START: BLURB
    blurbHide: PropTypes.bool.tag({
      group: "BLURB",
      name: "Hide Blurb?",
      description: "If checked, display blurb.",
      defaultValue: defaults.blurbHide
    }),
    descriptionText: PropTypes.richtext.tag({
      group: "BLURB",
      name: "Blurb content",
      defaultValue: defaults.descriptionText
    }),
    blurbNumExtrasToShow: PropTypes.number.tag({
      group: "BLURB",
      name: "Show X extra blurbs",
      description: "Enter X to show that many extra blurbs. X defaults to 0.",
      defaultValue: defaults.blurbNumExtrasToShow
    }),
    blurbOrderOfExtras: PropTypes.string.tag({
      group: "BLURB",
      name: "Extra blurbs order",
      description:
        "Enter the order in which the extra blurbs should appear, e.g. '3,1,2'. Blank is the default and means '1,2,3,4,5,6,7' up to 'X' where 'X' is the number of extra blurbs to show.",
      defaultValue: defaults.blurbOrderOfExtras
    }),

    // START: BYLINE
    siglineHide: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Hide Sigline",
      description: "If checked, hide the Sigline (default un-checked).",
      defaultValue: defaults.siglineHide,
      hidden: true
    }),
    bylineShow: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Show byline",
      description:
        "Check this to show the byline. Otherwise, it will be suppressed.",
      defaultValue: defaults.bylineShow
    }),
    bylineShowOpinion: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Opinion byline",
      description:
        "Check this to show the opinion byline. Otherwise, it will be suppressed.",
      defaultValue: defaults.bylineShowOpinion
    }),
    bylineUseAlternate: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Use alternate byline",
      description:
        "Check this to enter an alternate byline. Edit it in the WYSIWYG. Otherwise, the byline associated with the underlying content will be used. NOTE: When there is no backing content, it is as if this button has been checked.",
      defaultValue: defaults.bylineUseAlternate
    }),
    timestampShow: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Show timestamp",
      description:
        "Check this to show the timestamp. Otherwise, it will be suppressed.",
      defaultValue: defaults.timestampShow
    }),
    timestamp: PropTypes.dateTime.tag({
      group: "BYLINE",
      name: "Timestamp",
      description:
        "Use the datetime picker to set the display date for this story. If blank, the display date of the story accompanying this feature will be used.",
      defaultValue: defaults.timestamp
    }),
    timestampUseFixedDateFormat: PropTypes.bool.tag({
      group: "BYLINE",
      name: "Use fixed date format?",
      description:
        "Check this to use a fixed date format set by input field below",
      defaultValue: defaults.timestampUseFixedDateFormat
    }),
    timestampFixedDateFormat: PropTypes.string.tag({
      group: "BYLINE",
      name: "Fixed date format",
      description:
        "Enter the format for the date object - see options at https://date-fns.org/v1.9.0/docs/format",
      defaultValue: defaults.timestampFixedDateFormat
    }),
    timestampDuration: PropTypes.number.tag({
      group: "BYLINE",
      name: "Show timestamp for X hours.",
      description:
        "Set this to show the timestamp for X hours. X defaults to 2. If a future date/time, the timestamp will show for X hours after that date/time.",
      defaultValue: defaults.timestampDuration
    }),
    timestampRecencyThreshold: PropTypes.number.tag({
      group: "BYLINE",
      name: "Show timestamp as red for X minutes",
      description:
        "Set this to show the red timestamp for X minutes. X defaults to 30.",
      defaultValue: defaults.timestampRecencyThreshold
    }),

    // START: RECIPE
    recipeShow: PropTypes.bool.tag({
      group: "RECIPE",
      name: "Show recipe info",
      description: "Check this to show the recipe info.",
      defaultValue: defaults.recipeShow,
      hidden: false
    }),

    // START: LIVE TICKER
    liveTickerHide: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Hide live ticker",
      description: "If checked, display ticker.",
      defaultValue: defaults.liveTickerHide
    }),
    liveTickerCanonicalUrl: PropTypes.string.tag({
      group: "LIVE TICKER",
      name: "Canonical URL",
      description:
        "Enter the canonical url of the LUF. Precedence is Live Ticker Canonical URL > Backing content.",
      defaultValue: defaults.liveTickerCanonicalUrl
    }),
    liveTickerNum: PropTypes.number.tag({
      group: "LIVE TICKER",
      name: "Show X live links on desktop",
      description:
        "Enter X to show that many related links. X defaults to 3. Minimum is 2. Maximum is 8.",
      defaultValue: defaults.liveTickerNum
    }),
    liveTickerNumMobileOnly: PropTypes.string.tag({
      group: "LIVE TICKER",
      name: "Show X live links on mobile",
      description:
        "Enter X to show that many related links at mobile breakpoints. X defaults to -1 which means to use the dektop value. Minimum is 2. Maximum is 8.",
      defaultValue: defaults.liveTickerNumMobileOnly
    }),
    liveTickerUpdatesShow: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Show normal updates",
      description: "If checked, show posts with the live-update subtype.",
      defaultValue: defaults.liveTickerUpdatesShow
    }),
    liveTickerOutcomesShow: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Show 'Live Outcomes'",
      description: "If checked, show posts with the live-outcome subtype.",
      defaultValue: defaults.liveTickerOutcomesShow
    }),
    liveTickerRaceCallsShow: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Show 'Race Calls'",
      description: "If checked, show posts with the live-race-call subtype.",
      defaultValue: defaults.liveTickerRaceCallsShow
    }),
    liveTickerReporterInsightsShow: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Show 'Reporter Insights'",
      description:
        "If checked, show posts with the live-reporter-insight subtype.",
      defaultValue: defaults.liveTickerReporterInsightsShow
    }),
    liveTickerTimestampShow: PropTypes.bool.tag({
      group: "LIVE TICKER",
      name: "Show Timestamp",
      description: "If checked, show timestamp for each link.",
      defaultValue: defaults.liveTickerTimestampShow
    }),
    liveTickerLabelType: PropTypes.oneOf(Object.keys(liveTickerLabelTypes)).tag(
      {
        group: "LIVE TICKER",
        name: "Label type",
        description: "Select the look of the the label.",
        defaultValue: defaults.liveTickerLabelType,
        hidden: true
      }
    ),
    liveTickerLabel: PropTypes.string.tag({
      group: "LIVE TICKER",
      name: "Label text",
      description: "Enter label to appear above the live ticker.",
      defaultValue: defaults.liveTickerLabel
    }),
    liveTickerLabelNameSpace: PropTypes.string.tag({
      group: "LIVE TICKER",
      name: "Label namespace",
      description: "Enter label namespace to facilitate content editable.",
      defaultValue: defaults.liveTickerLabelNameSpace,
      hidden: true
    }),

    // START: RELATED LINKS
    relatedLinksHide: PropTypes.bool.tag({
      group: "RELATED LINKS",
      name: "Hide Related links",
      description: "If checked, hide the related links (default un-checked).",
      defaultValue: defaults.relatedLinksHide,
      hidden: true
    }),
    relatedLinksPosition: PropTypes.oneOf(
      Object.keys(relatedLinksPositions)
    ).tag({
      group: "RELATED LINKS",
      name: "Related links position",
      description: "Select the position of the related links.",
      defaultValue: defaults.relatedLinksPosition
    }),
    relatedLinksNum: PropTypes.number.tag({
      group: "RELATED LINKS",
      name: "Show X related links on desktop",
      description: "Enter X to show that many related links. X defaults to 0.",
      defaultValue: defaults.relatedLinksNum
    }),
    relatedLinksNumMobileOnly: PropTypes.string.tag({
      group: "RELATED LINKS",
      name: "Show X related links on mobile",
      description:
        "Enter X to show that many related links at mobile breakpoints. X defaults to -1 which means to use the desktop value.",
      defaultValue: defaults.relatedLinksNumMobileOnly
    }),
    relatedLinksOrder: PropTypes.string.tag({
      group: "RELATED LINKS",
      name: "Related links order",
      description:
        "Enter the order in which related links should appear, e.g. '3,1,2'. Blank is the default and means '1,2,3,4,5,6,7' up to 'X' where 'X' is the number of related links to show.",
      defaultValue: defaults.relatedLinksOrder
    }),
    relatedLinksStyle: PropTypes.oneOf(Object.keys(relatedLinksStyles)).tag({
      group: "RELATED LINKS",
      name: "Related links style",
      description: "Select the style of the related links.",
      defaultValue: defaults.relatedLinksStyle
    }),
    relatedLinksSize: PropTypes.oneOf(Object.keys(relatedLinksSizes)).tag({
      labels: relatedLinksSizes,
      group: "RELATED LINKS",
      name: "Related links size",
      description:
        "Choose how large the related links should be. If 'stacked', only the default size will show bullets.",
      defaultValue: defaults.relatedLinksSize
    }),
    relatedLinksLabelType: PropTypes.oneOf(
      Object.keys(relatedLinksLabelTypes)
    ).tag({
      group: "RELATED LINKS",
      name: "Label type",
      description: "Select the look of the the label.",
      defaultValue: defaults.relatedLinksLabelType,
      hidden: false
    }),
    relatedLinksLabel: PropTypes.string.tag({
      group: "RELATED LINKS",
      name: "Label text",
      description: "Enter label to appear above related links.",
      defaultValue: defaults.relatedLinksLabel
    }),
    relatedLinksLabelNameSpace: PropTypes.string.tag({
      group: "RELATED LINKS",
      name: "Label namespace",
      description: "Enter label namespace to facilitate content editable.",
      defaultValue: defaults.relatedLinksLabelNameSpace,
      hidden: true
    }),

    // START: AUDIO
    audioHide: PropTypes.bool.tag({
      group: "AUDIO",
      name: "Hide Audio",
      description: "If checked, hide the Audio (default un-checked).",
      defaultValue: defaults.audioHide
    }),
    audioAllowArticleType: PropTypes.oneOf(
      Object.keys(audioAllowArticleTypes)
    ).tag({
      labels: audioAllowArticleTypes,
      group: "AUDIO",
      name: 'Allow "Listen to article"',
      description:
        "Select when to show the audio article (defaults to 'Never').",
      defaultValue: defaults.audioAllowArticleType,
      hidden: false
    }),
    audioCanonicalUrl: PropTypes.string.tag({
      group: "AUDIO",
      name: "Canonical URL",
      description:
        "Enter the canonical url of the podcast. Precedence is Audio URL > Canonical URL >  Podcast Slug.",
      defaultValue: defaults.audioCanonicalUrl
    }),
    audioUrl: PropTypes.string.tag({
      group: "AUDIO",
      name: "Audio URL",
      description:
        "Enter the URL of the audio file (.mp3, .ogg, or .wav) to be heard. Precedence is Audio URL > Podcast Slug > Canonical URL.",
      defaultValue: defaults.audioUrl
    }),
    audioListenText: PropTypes.string.tag({
      group: "AUDIO",
      name: "Listen text",
      description:
        "Enter the listen text. If present, the scrubber will not appear.",
      defaultValue: defaults.audioListenText
    }),
    audioPosition: PropTypes.oneOf(Object.keys(audioPositions)).tag({
      group: "AUDIO",
      name: "Player Position",
      description: "Select the position of the audio player.",
      defaultValue: defaults.audioPosition
    }),
    audioArtOverlayShow: PropTypes.boolean.tag({
      name: "Show audio art overlay",
      group: "AUDIO",
      defaultValue: defaults.audioArtOverlayShow,
      description:
        "NOTE: This will take precedence over the normal art overlay.",
      hidden: true
    }),

    // START: CTA
    ctaLabelShow: PropTypes.bool.tag({
      group: "Call To Action",
      name: "Show CTA",
      defaultValue: defaults.ctaLabelShow,
      hidden: false
    }),
    ctaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      group: "Call To Action",
      name: "Type",
      defaultValue: defaults.ctaLabelType,
      hidden: false
    }),
    ctaLabel: PropTypes.string.tag({
      group: "Call To Action",
      name: "Text",
      defaultValue: defaults.ctaLabel,
      hidden: false
    }),
    ctaLabelUrl: PropTypes.string.tag({
      group: "Call To Action",
      name: "Link override",
      defaultValue: defaults.ctaLabelUrl,
      hidden: false
    }),
    ctaLabelLinkRemove: PropTypes.bool.tag({
      group: "Call To Action",
      name: "Remove label link",
      defaultValue: defaults.ctaLabelLinkRemove,
      hidden: true
    }),
    ctaLabelSecondaryShow: PropTypes.bool.tag({
      group: "Call To Action",
      name: "Show secondary label",
      defaultValue: defaults.ctaLabelSecondaryShow,
      hidden: false
    }),
    ctaLabelSecondary: PropTypes.string.tag({
      group: "Call To Action",
      name: "Secondary label",
      defaultValue: defaults.ctaLabelSecondary,
      hidden: false
    }),
    ctaLabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      group: "Call To Action",
      name: "Label alignment",
      defaultValue: defaults.ctaLabelAlignment,
      hidden: false
    }),
    ctaLabelIcon: PropTypes.oneOf([...Object.keys(labelIcons)]).tag({
      group: "Call To Action",
      name: "Select icon to prepend",
      defaultValue: defaults.ctaLabelIcon,
      hidden: false
    }),
    ctaLabelNameSpace: PropTypes.string.tag({
      group: "Call To Action",
      name: "Label namespace",
      defaultValue: defaults.ctaLabelNameSpace,
      hidden: true
    }),

    // START: footnote
    footnoteHide: PropTypes.bool.tag({
      group: "FOOTNOTE",
      name: "Hide footnote",
      defaultValue: defaults.footnoteHide,
      hidden: false
    }),
    footnoteText: PropTypes.string.tag({
      group: "FOOTNOTE",
      name: "Footnote text",
      defaultValue: defaults.footnoteText,
      hidden: false
    }),
    footnoteUrl: PropTypes.string.tag({
      group: "FOOTNOTE",
      name: "Footnote url",
      defaultValue: defaults.footnoteUrl,
      hidden: false
    }),
    footnoteAlignment: PropTypes.oneOf(
      Object.keys({
        inherit: "inherit",
        left: "left",
        center: "center",
        right: "right"
      })
    ).tag({
      group: "FOOTNOTE",
      labels: {
        inherit: "inherit",
        left: "left",
        center: "center",
        right: "right"
      },
      name: "Footnote alignment",
      defaultValue: defaults.footnoteAlignment,
      hidden: false
    }),
    // END: footnote

    // START: LIVE GRAPHICS
    liveGraphicsHide: PropTypes.bool.tag({
      group: "LIVE GRAPHICS",
      name: "Hide Live Graphics",
      description: "If checked, hide the live graphics (default un-checked).",
      defaultValue: defaults.liveGraphicsHide,
      hidden: true
    }),
    liveGraphicsContentConfig: PropTypes.contentConfig([
      "elex-live-graphic",
      "olympics-live-graphic"
    ]).tag({
      group: "LIVE GRAPHICS",
      name: "Live Graphics Content Config",
      defaultValue: defaults.liveGraphicsContentConfig
    }),
    liveGraphicsUseScreenshot: PropTypes.bool.tag({
      group: "LIVE GRAPHICS",
      name: "Force screenshot",
      description:
        "If checked, a screenshot will be used if available. NOTE: The 2024 Olympics live graphics have no screenshots.",
      defaultValue: defaults.liveGraphicsUseScreenshot
    }),

    // START: FEED
    limit: PropTypes.number.tag({
      group: "FEED",
      name: "Show X items on desktop",
      description:
        "Enter the number of items to show on desktop. Default is 5.",
      defaultValue: defaults.limit
    }),
    limitMobileOnly: PropTypes.string.tag({
      group: "FEED",
      name: "Show X items on mobile",
      description:
        "Enter the number of items to show on mobile. Defaults to the desktop value. Cannot be more than the desktop value.",
      defaultValue: defaults.limitMobileOnly
    }),
    offset: PropTypes.number.tag({
      group: "FEED",
      name: "Offset",
      description: "Enter the offset. Default is 0.",
      defaultValue: defaults.offset
    }),
    // START: TOPPER LABEL in FEED group
    topperLabelShow: PropTypes.bool.tag({
      group: "FEED",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      defaultValue: defaults.topperLabelShow
    }),
    topperLabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      group: "FEED",
      name: "Type",
      description: "Select the look of the the label.",
      defaultValue: defaults.topperLabelType
    }),
    topperLabel: PropTypes.string.tag({
      group: "FEED",
      name: "Label text",
      description: "The label text. Optional.",
      defaultValue: defaults.topperLabel
    }),
    topperLabelUrl: PropTypes.string.tag({
      group: "FEED",
      name: "Label link override",
      description: "The label link. Default value matches headline link.",
      defaultValue: defaults.topperLabelUrl
    }),
    topperLabelSecondaryShow: PropTypes.bool.tag({
      group: "FEED",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      defaultValue: defaults.topperLabelSecondaryShow
    }),
    topperLabelSecondary: PropTypes.string.tag({
      group: "FEED",
      name: "Secondary label",
      description: "The secondary label text. Optional.",
      defaultValue: defaults.topperLabelSecondary
    }),
    topperLabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      group: "FEED",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'.",
      defaultValue: defaults.topperLabelAlignment
    }),
    topperLabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      group: "FEED",
      name: "Select icon to prepend",
      description:
        "The label icon to prepend to the label. Defaults to 'None'.",
      defaultValue: defaults.topperLabelIcon
    }),
    topperLabelNameSpace: PropTypes.string.tag({
      group: "FEED",
      name: "Label namespace",
      description: "Enter label namespace to facilitate content editable.",
      defaultValue: defaults.topperLabelNameSpace,
      hidden: true
    }),

    // START: Count
    countShow: PropTypes.bool.tag({
      group: "COUNT",
      name: "Show count",
      description: "If checked, a count may appear to the right of the feature",
      defaultValue: defaults.countShow
    }),
    countOverride: PropTypes.number.tag({
      group: "COUNT",
      name: "Count override to show",
      description:
        "Optional for feeds. Enter the count override. Use this if the count on the content is not satisfactory or available.",
      defaultValue: defaults.countOverride
    }),
    countSize: PropTypes.oneOf(Object.keys(countSizes)).tag({
      labels: countSizes,
      group: "COUNT",
      name: "Count size",
      description: "Optional. Choose how large the count should be.",
      defaultValue: defaults.countSize
    }),

    // START: These are set programmatically. Be careful
    thereIsBackingContent: PropTypes.bool.tag({
      name: "There is backing content",
      defaultValue: false,
      description: "If valid content in content config, this will be true",
      hidden: true
    }),
    thereIsCustomContentTantamountToContent: PropTypes.bool.tag({
      name: "There is custom content tantamount to backing content",
      defaultValue: false,
      description:
        "If there is enough content in the valid content or local edits, this will be true",
      hidden: true
    })
  })
};
