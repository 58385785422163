import get from "lodash.get";
import kebabcase from "lodash.kebabcase";
import { getCompoundLabel } from "~/shared-components/story-card/_children/Label.helpers";
import { makeGetKey } from "~/shared-components/story-card/_children/Label.toolbar";
import defaults from "./custom-field-defaults";

const tables = [
  "chain",
  "table0",
  "table1",
  "table2",
  "table3",
  "table4",
  "table5",
  "table6",
  "table7",
  "table8",
  "tableAd1",
  "table9"
];

const getNamespace = ({ table = "", namespace = "" }) => {
  if (/chain/.test(table) || !table) return namespace;
  return `${table}${namespace.charAt(0).toUpperCase()}${namespace.slice(1)}`;
};

function getLabelOverrides({ table, customFields }) {
  const ns = getNamespace({ table });
  const getKey = makeGetKey(ns);
  return {
    labelShow: customFields[getKey("labelShow")],
    labelType: customFields[getKey("labelType")],
    label: customFields[getKey("label")],
    labelUrl: customFields[getKey("labelUrl")],
    labelSecondaryShow: customFields[getKey("labelSecondaryShow")],
    labelSecondary: customFields[getKey("labelSecondary")],
    labelAlignment: customFields[getKey("labelAlignment")],
    labelIcon: customFields[getKey("labelIcon")],
    labelNameSpace: ns
  };
}

function getFormOverrides({ table, customFields }) {
  const ns = getNamespace({ table, namespace: "form" });
  const getKey = makeGetKey(ns);
  return {
    formShow: customFields[getKey("show")],
    // formMethod: customFields[getKey("method")], Not implementing. hard-coded to "search" until other type is needed
    formAction: customFields[getKey("action")],
    // formType: customFields[getKey("type")], Not implementing. hard-coded to "search" until other type is needed
    formParam: customFields[getKey("param")],
    formPlaceholder: customFields[getKey("placeholder")],
    formNameSpace: ns
  };
}

export function getLabelObj({ customFields, isAdmin = false }) {
  const labelObj = {};
  tables.forEach((table) => {
    const item = getCompoundLabel({
      isAdmin,
      overrides: {
        ...getLabelOverrides({ table, customFields }),
        ...getFormOverrides({ table, customFields })
      }
    });
    labelObj[table] = item;
  });
  return labelObj;
}

export const getCtaLabelObj = ({ customFields, isAdmin = false }) => {
  const labelObj = {};
  tables.forEach((table) => {
    const namespace = getNamespace({ table });
    const getKey = makeGetKey(namespace);
    const ctaNs = getNamespace({ table, namespace: "cta" });
    const getCtaKey = makeGetKey(ctaNs);

    // NOTE: If no cta url, use label url if label is due to show
    let labelUrl = customFields[getCtaKey("labelUrl")];
    if (
      !labelUrl &&
      customFields[getKey("labelShow")] &&
      customFields[getKey("label")]
    ) {
      labelUrl = customFields[getKey("labelUrl")];
    }

    const item = getCompoundLabel({
      isAdmin,
      overrides: {
        labelShow: customFields[getCtaKey("labelShow")],
        labelType:
          customFields[getCtaKey("labelType")] || defaults.ctaLabelType,
        // NOTE: If no labelUrl, indicate that by blanking out the label
        label: !isAdmin && !labelUrl ? "" : customFields[getCtaKey("label")],
        labelUrl,
        labelAlignment: "center",
        labelNameSpace: ctaNs

        // NOTE: If cta gets a search form
        // ...getFormOverrides({ table: ctaNs, customFields })
      }
    });
    labelObj[table] = item;
  });
  return labelObj;
};

export const getLinkGroupAttr = (linkGroup, labelObj) => {
  // eslint-disable-next-line max-len
  const re =
    /^(Arts\s+&\s+Entertainment|Going\s+Out\s+Guide|Lifestyle|National|Opinions|Real\s+Estate|World)$/i;
  if (!linkGroup && labelObj) {
    linkGroup = re.test(get(labelObj, "label.text")) ? RegExp.$1 : "";
  }
  linkGroup = linkGroup ? kebabcase(linkGroup.toLowerCase()) : "";
  return linkGroup ? { "data-link-group": linkGroup } : {};
};
