import extractRawMetadata from "./extract-raw-metadata";
import formatTracking from "./format-tracking";

/**
 * Receives an ANS content object and creates a formatted tracking object for
 * use by analytics providers.
 */
export default function createFormattedTrackingMetadata(ans) {
  try {
    const rawTracking = extractRawMetadata(ans);
    return formatTracking(rawTracking);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
}
