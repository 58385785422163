// Defaults for top-table chain and table custom fields, so they can be viewed in one place

const SHOW_HIDE = {
  hideValidationMsg: false,
  hideHelperLabels: false,
  hideFromDesktop: false,
  hideFromMobile: false,
  hideFromWeb: false,
  hideFromApps: false
};

const DUPLICATES = {
  suppressDuplicates: false,
  discountDuplicates: false
};

const LABEL = {
  labelShow: false,
  labelType: "Package",
  labelPosition: "Default",
  label: "",
  labelUrl: "",
  labelSecondaryShow: false,
  labelSecondary: "",
  labelAlignment: "left",
  labelIcon: "None"
};

const FORM = {
  formShow: false,
  // formMethod: "GET", Not implementing. hard-coded to "GET" until "POST" is needed
  formAction: "",
  // formType: "search", Not implementing. hard-coded to "search" until other type is needed
  formParam: "",
  formPlaceholder: ""
};

const CTA = {
  ctaLabelShow: false,
  ctaLabelType: "CTA",
  ctaLabel: "",
  ctaLabelUrl: ""
};

const LINK_GROUPS = {
  linkGroup: "",
  table0LinkGroup: "",
  table1LinkGroup: "",
  table2LinkGroup: "",
  table3LinkGroup: "",
  table4LinkGroup: "",
  table5LinkGroup: "",
  table6LinkGroup: "",
  table7LinkGroup: "",
  table8LinkGroup: "",
  tableAd1LinkGroup: "",
  table9LinkGroup: ""
};

const DIVIDERS = {
  includeDividers: true,
  table0HorizontalDividerWeight: "none",
  table0VerticalDividerWeight: "normal",
  table1HorizontalDividerWeight: "normal",
  table1VerticalDividerWeight: "normal",
  table2HorizontalDividerWeight: "normal",
  table2VerticalDividerWeight: "normal",
  table3HorizontalDividerWeight: "normal",
  table3VerticalDividerWeight: "normal",
  table4HorizontalDividerWeight: "normal",
  table4VerticalDividerWeight: "normal",
  table5HorizontalDividerWeight: "normal",
  table5VerticalDividerWeight: "normal",
  table6HorizontalDividerWeight: "normal",
  table6VerticalDividerWeight: "normal",
  table7HorizontalDividerWeight: "normal",
  table7VerticalDividerWeight: "normal",
  table8HorizontalDividerWeight: "normal",
  table8VerticalDividerWeight: "normal",
  tableAd1HorizontalDividerWeight: "normal",
  tableAd1VerticalDividerWeight: "normal",
  table9HorizontalDividerWeight: "normal",
  table9VerticalDividerWeight: "normal"
};

const defaults = {
  displayName: "Multi-Table Chain",
  rowSpan: 1,
  layout: "Tiling",
  disableLazyLoading: false,
  personalizedOrdering: false,
  mappedTopicName: "",
  bottomSeparator: "Large",
  ...DUPLICATES,
  ...LABEL,
  ...FORM,
  ...CTA,
  ...SHOW_HIDE,
  ...LINK_GROUPS,
  ...DIVIDERS
};

export default defaults;
