import getEnv from "../components/utilities/env";
import { internalApiUrl } from "./assmblrEnv";
import { fusionApiHeaders } from "../next-fusion-compat/constants";
import { fetchAsyncContent } from "./fusion-modules/content";
import { internal } from "../prism.config";

export const TARGET_DOMAIN_NAME = "washingtonpost";
export const TARGET_PATH = "/assembler";
export const TARGET_ENV = "prod";

const ENV = getEnv();

export const handlePopState = () => {
  if (ENV === TARGET_ENV) {
    if (window.location.hostname.includes(TARGET_DOMAIN_NAME)) {
      const [firstPathSegment] = window.location.path.split("/");

      if (firstPathSegment === TARGET_PATH) {
        return false;
      }
    }
  }

  return true;
};

const fetchAppropriateEndpt = async ({ source, slug }) => {
  if (!/^([A-Za-zÀ-ÖØ-öø-ÿ]|\d|-)*$/.test(slug)) return {};
  const isVercel = process.env.VERCEL_ENV;
  const path =
    source === "assembler-snapshot" ? "published-snapshot" : "rendering";

  if (isVercel) {
    const rendering = await fetchAsyncContent({
      source,
      query: {
        slug,
        env: ENV
      }
    });

    return {
      rendering,
      ...(rendering?.lastPublishedActualTimestamp
        ? { timestamp: rendering.lastPublishedActualTimestamp }
        : {})
    };
  }

  const res = await fetch(`${internalApiUrl}/${path}/${slug}`);
  if (!res.ok) {
    if (res.status === 404) return {};
    throw res.text() || res;
  }
  const rendering = await res?.json();

  return {
    rendering,
    timestamp: rendering.lastPublishedActualTimestamp
  };
};

export const fetchRendering = async (slug) => {
  return fetchAppropriateEndpt({ slug, source: "assembler-rendering" });
};

export const fetchSnapshot = async (slug) => {
  return fetchAppropriateEndpt({ slug, source: "assembler-snapshot" });
};

export const fetchSnapshotVersion = async (version, overrideEnv) => {
  // will not work with vercel atm
  return fetch(
    `${internalApiUrl}/published-snapshot/version/${version}?env=${overrideEnv}`
  ).then((r) => r.json());
};

export const optimizeSlugs = {
  showTimestamps: "showTimestamps"
};

export const getContentCacheArgs = (referrer, data = {}, url) => ({
  url:
    url ||
    (internal ? `${internal}/api` : "https://www.washingtonpost.com/prism/api"),
  headers: {
    ...fusionApiHeaders,
    Referer: referrer
  },
  data
});

export const return404 = (res, message) => {
  // eslint-disable-next-line no-console
  if (message) console.error(message);
  res.statusCode = 404;
  return res.end(JSON.stringify({ status: 404, message }));
};

export const return500 = (res, message) => {
  // eslint-disable-next-line no-console
  if (message) console.error(message);
  res.statusCode = 500;
  res.end(JSON.stringify({ status: 500, message }));
  return null;
};
