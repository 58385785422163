import React, { memo, Fragment, useRef, useEffect, useState } from "react";
import { useAppContext } from "fusion-context";
import { useContent } from "fusion-content";
import get from "lodash.get";
import { AdSlot } from "@washingtonpost/site-ads";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { getQueryParams } from "../../../utilities/history";
import { isMobile as isMobileFunc } from "../../../utilities/device-helper";
import { isAdFree } from "../../../utilities/login-details";
import { useChainContext } from "~/shared-components/ChainContext";
import {
  adPropTypes,
  ZeusAdPropTypes,
  ZeusPropTypes
} from "../../../../proptypes/zeus";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useUser } from "../../../contexts/user-context";

/**
 * Ad markup at its most basic.
 * Note: React.memo is used to prevent future updates once the component has rendered initially.
 * This is so that we dont override the zeus rendering
 * Ads require wp-ad element.
 * Ads do not support flex.
 * Also important to note: there is a potential that memo might not prevent all renders as
 * React.memo was intended as a performance optimization, rather than a render prevention method.
 *
 * @param {string} adType type of ad, e.g. flex_ss_bb_hp
 * @param {string} wrapperClasses visibility classes for the ad wrapper. Do not use for positioning.
 * @returns {object} Returns React component representing ad
 */
export const AdMarkup = memo(
  function adMarkup({
    wrapperClasses = "",
    adType,
    elementRef,
    minHeight,
    wrapperRef
  }) {
    return (
      <div
        className={wrapperClasses}
        ref={wrapperRef}
        style={{ minHeight }}
        data-testid="wp-ad-wrapper"
      >
        <wp-ad
          ref={elementRef}
          class="hide-for-print"
          id={`slug_${adType}`}
          data-testid={`wp-ad-slug_${adType}`}
        />
      </div>
    );
  },
  () => true
);

AdMarkup.propTypes = adPropTypes;

const wrapperClasses = ({ adType }) =>
  getClasses("", {
    "db dn-ns": adType === "interstitial",
    "dn db-ns": adType === "desktop_interstitial"
  });

/**
 * Determine if ads should show
 *
 * @returns {bool} Whether or not the ad should show
 */
const shouldShowAds = ({
  showAds,
  adType,
  disableInterstitial,
  removableAds
}) => {
  // removableAds array should contain either 'fixedBottom' or 'interstitial' when mobile softwall is displayed
  const removeAdDueToMobileSoftwall = removableAds.includes(adType);
  if (
    (disableInterstitial &&
      (adType === "interstitial" || adType === "desktop_interstitial")) ||
    removeAdDueToMobileSoftwall
  ) {
    return false;
  }

  return showAds;
};

const MAX_BIGBOX_AD_HEIGHT = 324;

const BigBoxSpacer = styled("div", {
  display: "flex",
  justifyContent: "center",
  marginTop: theme.space["125"],
  marginBottom: theme.space["150"],
  // Style the ad label
  ".adslot-c > div:first-child": {
    marginBottom: theme.space["125"]
  },
  // Style the inner ad container
  ".adslot-c > div:nth-child(2)": {
    marginTop: theme.space["175"]
  }
});

const BigBoxDividers = styled("div", {
  borderTop: `${theme.colors.faint} 1px solid`,
  borderBottom: `${theme.colors.faint} 1px solid`,
  marginBottom: theme.space["225"],
  variants: {
    isInTableAd: {
      true: {
        borderTop: "transparent",
        borderBottom: "transparent",
        marginBottom: `calc(0rem - ${theme.space["200"]})`,
        marginTop: `calc(0rem - ${theme.space["200"]})`,
        paddingTop: 0
      }
    }
  }
});

const LeaderboardAdSlot = styled(AdSlot, {
  variants: {
    isHomepageTopLeaderboard: {
      true: {
        backgroundColor: theme.colors.gray600,
        borderBottom: `1px solid ${theme.colors.gray400} !important`,
        paddingTop: theme.space["150"],
        paddingBottom: theme.space["150"]
      }
    }
  }
});

/**
 * Create a Zeus ad component.
 * */
export const ZeusAd = ({
  adType = "",
  isAdmin,
  maxAdConfig,
  globalContent: { tracking: { show_ads: showAdsString = true } = {} } = {},
  disableInterstitial,
  contentType
}) => {
  // NOTE: in-table ads get differnent classNames
  const isInTableAd = !!(useChainContext() || {})?.layout;
  const [isMobileWidth, setIsMobileWidth] = useState(false);
  const [hasTestParam, setHasTestParam] = useState(false);
  const elementRef = useRef();
  const wrapperRef = useRef();
  const { removableAds = [] } = useUser();

  const [showAds, setShowAds] = useState(
    showAdsString === true || showAdsString === "true"
  );

  const takeoverAd = useRef({ height: null });
  const maxAdByAdType = get(
    maxAdConfig,
    ["adHeightOnCommercialNode", "washingtonpost.com", adType],
    ""
  );

  const maxAdHeight = get(maxAdByAdType, "minHeight", undefined);

  if (maxAdByAdType) {
    const flightDates = get(maxAdByAdType, "flightDates", "");
    flightDates.forEach((date) => {
      const now = Date.now();
      if (now >= Date.parse(date.start) && now <= Date.parse(date.end)) {
        takeoverAd.current.height = maxAdHeight;
      }
    });
  }

  const hpleaderboardType = [
    "leaderboard_1",
    "leaderboard_2",
    "leaderboard_3",
    "leaderboard_4"
  ];

  /**
   * Render client-side only
   * This is necessary to work with hydrate
   */
  useEffect(() => {
    // Before completing of checking isMobileWidth, el with 'slug_fixedBottom' id may be added from wp-ad.js
    // checking document.getElementById("slug_fixedBottom") will be removed once wp-ad.js script is updated
    setIsMobileWidth(
      isMobileFunc() && document.getElementById("slug_fixedBottom") === null
    );
  }, []);

  useEffect(() => {
    const takeoverQP = getQueryParams(window.location.href).takeover || "";
    if (!hasTestParam && takeoverQP && takeoverQP === adType) {
      setHasTestParam(true);
      takeoverAd.current.height = 600;
    }
  }, [adType, hasTestParam]);

  const isAdFreeSub = isAdFree();

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Explicitly hide ads, and do not include min-height, if no_ads=true
      if (window.location.search.includes("no_ads=true")) {
        setShowAds(false);
      } else {
        setShowAds(!isAdFreeSub);
      }
    }
  }, [isAdFreeSub]);

  if (!shouldShowAds({ showAds, adType, disableInterstitial, removableAds })) {
    return null;
  }

  if (isAdmin) {
    return (
      <div
        className="placeholder-ad-slot center"
        data-testid="zeus-placeholder"
      >
        <strong>Zeus Ad</strong>
        <br /> {adType}
      </div>
    );
  }

  if (hpleaderboardType.includes(adType)) {
    const isTopLeaderboard = adType === "leaderboard_1";
    const isHomepageTopLeaderboard =
      isTopLeaderboard && contentType === "homepage";

    const adHeight =
      takeoverAd.current.height || (isTopLeaderboard ? 250 : 280);
    const placeholderHeight =
      takeoverAd.current.height || (isHomepageTopLeaderboard ? 90 : adHeight);

    return (
      <LeaderboardAdSlot
        slotId={adType}
        adHeight={adHeight}
        placeholderHeight={placeholderHeight}
        space={isHomepageTopLeaderboard ? "" : "md"}
        className="dn-hp-xs border-box adslot-c" // adslot-c is for unit test
        label=""
        dividers={!isTopLeaderboard}
        isHomepageTopLeaderboard={isHomepageTopLeaderboard}
      />
    );
  }

  if (adType.includes("bigbox")) {
    // NOTE: bigbox family can be an in-table ad. When it is, it must behave a little differently
    return (
      <BigBoxDividers
        isInTableAd={isInTableAd}
        className={getClasses("", {
          "dn-hp-sm-to-mx": !isInTableAd
        })}
      >
        <BigBoxSpacer
          className={getClasses("", {
            "dn-hp-sm-to-mx": !isInTableAd
          })}
        >
          <AdSlot
            slotId={adType}
            adHeight={MAX_BIGBOX_AD_HEIGHT + 30} // 30px for the label
            placeholderHeight={250}
            placeholderWidth={300}
            mtSpace="xxs"
            className={getClasses("border-box adslot-c w-100", {
              // adslot-c is for unit test
              "dn-hp-sm-to-mx": !isInTableAd
            })}
            label="Advertisement"
            showOnlyLabel={true}
          />
        </BigBoxSpacer>
      </BigBoxDividers>
    );
  }

  if (adType.includes("bs_card_front")) {
    // NOTE: bs_card_front family only appears in-table. If it appears elsewhere,
    // additional configuration will be needed
    return (
      <AdMarkup
        adType={adType}
        elementRef={elementRef}
        wrapperRef={wrapperRef}
      />
    );
  }

  if (adType === "fixedBottom") {
    return isMobileWidth ? (
      <AdMarkup
        adType={adType}
        elementRef={elementRef}
        wrapperClasses={"fixed bottom-0 left-0 w-100 bg-offwhite z-5 dn-ns"}
        wrapperRef={wrapperRef}
      />
    ) : null;
  }

  return (
    <Fragment>
      <AdMarkup
        adType={adType}
        elementRef={elementRef}
        wrapperRef={wrapperRef}
        wrapperClasses={wrapperClasses({ adType })}
      />
    </Fragment>
  );
};

ZeusAd.propTypes = ZeusAdPropTypes;

const FusionZeus = ({ adType, customFields, globalContent }) => {
  const [isClientSide, setIsClientSide] = useState(false);
  const { metaValue = () => "homepage", isAdmin } = useAppContext();
  const { isMobile } = useAssemblerContext();
  const contentType = metaValue("contentType");

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  const maxAdConfig =
    useContent({
      source: "zeus-max-ad-config"
    }) || null;

  if (!maxAdConfig || (isMobile && !isClientSide)) return null;

  return (
    <ZeusAd
      {...customFields}
      isAdmin={isAdmin}
      maxAdConfig={maxAdConfig}
      globalContent={globalContent}
      contentType={contentType}
      wrapperClasses={wrapperClasses({ adType })}
    />
  );
};

FusionZeus.propTypes = ZeusPropTypes;
export default FusionZeus;
