import PropTypes from "prop-types";

import get from "lodash.get";
import kebabcase from "lodash.kebabcase";
import { format } from "date-fns-tz";
import getResizedUrl from "~/components/core/elements/image/resize-url";
import { fetchProps } from "../_utilities/data";

import { getLink } from "../_utilities/helpers";
import { getAuthorObjects, getCompoundBylineText } from "./Sigline.helpers";

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is human-read audio
 */
const isHumanReadArticle = (content) =>
  !!content?.additional_properties?.audio_article?.human?.raw_url;

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is robot-read audio
 */
const isRobotReadArticle = (content) =>
  !!content?.additional_properties?.audio_article?.automated?.manifest?.voices
    ?.length;

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is an audio article, either human- or robot-read
 */
export const isAudioArticle = (content) =>
  isHumanReadArticle(content) || isRobotReadArticle(content);

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is a audio-api-shaped podcast
 */
const isLionfishPodcast = (content) => !!content?.audio?.podTracUrl;

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is an ANS-shaped podcast
 */
const isAnsPodcast = (content) =>
  !!content?.additional_properties?.audio?.[0]?.podtrac_url;

/**
 * @param {object} content - The content to check
 * @returns {boolean} - Whether the object is a podcast, either audio-api- or ans-shaped
 */
export const isPodcast = (content) =>
  isAnsPodcast(content) || isLionfishPodcast(content);

// NOTE: props to fetch for jsonapp for podcasts
// NOTE: First key is for ANS, second key is for lionfish, this means when used with
// fetchProps that both content and overrides need to be the content
const podcastJsonAppKeys = {
  coverImage: [
    "additional_properties.series_meta.images.coverImage",
    "images.coverImage"
  ],
  displayDate: ["display_date", "publicationDate"],
  duration: ["additional_properties.audio[0].duration", "duration"],
  publicationDisplayDate: [
    "additional_properties.publicationDisplayDate",
    "publicationDisplayDate"
  ],
  seriesSlug: [
    "additional_properties.series_meta.seriesSlug",
    "seriesMeta.seriesSlug"
  ],
  seriesName: [
    "additional_properties.series_meta.seriesName",
    "seriesMeta.seriesName"
  ],
  slug: ["canonical_url", "slug"],
  title: ["headlines.basic", "title"]
};

// NOTE: props to fetch for jsonapp for audio article
const commonJsonAppKeys = {
  displayDate: ["display_date"],
  displayLabel: ["label_display.basic.text"],
  displayLabelUrl: ["label_display.basic.url"],
  displayTransparency: ["label_display.transparency.text"],
  excerpt: ["fusion_additions.excerpt", "excerptText"],
  title: ["headlines.basic"],
  titlePrefix: ["label_display.basic.headline_prefix"]
};

const showAudioArticle = ({ content, overrides }) => {
  const { audioAllowArticleType: type } = overrides;
  if (/none/.test(type)) return false;
  const audioAddlProps = get(content, "additional_properties.audio_article");
  return !/any/.test(type) ? !!get(audioAddlProps, type) : !!audioAddlProps;
};

const getTextObject = ({ overrides }) => {
  const { audioListenText: listen = "" } = overrides;
  return listen ? { listen } : undefined;
};

const getAudioArticleData = ({ content, podcastData, overrides }) => {
  let audioArticleData;
  if (podcastData) {
    if (get(podcastData, "additional_properties.audio_article"))
      audioArticleData = podcastData;
  } else if (get(content, "additional_properties.audio_article")) {
    audioArticleData = content;
  }
  return showAudioArticle({ content: audioArticleData, overrides })
    ? audioArticleData
    : undefined;
};

const getPromoImage = ({ content }) => {
  const promoImage = {
    aspectRatio: get(content, "fusion_additions.promo_image.aspect_ratio", 1.5),
    caption: get(
      content,
      "fusion_additions.promo_image.credits_display",
      undefined
    ),
    url: get(content, "fusion_additions.promo_image.url")
  };
  return promoImage?.url ? promoImage : undefined;
};

/**
 * Get the lead art element
 * @param {Object} content
 * @returns {Object} The lead art element
 */
const getLedeArtElement = (url) => (url ? { promo_image: { url } } : undefined);

/**
 * Returns the audio article object
 * @param {object} human The human object
 * @param {object} automated The automated object
 * @returns {object} The audio article object
 */
const getAudioArticleObject = ({ human = {}, automated }) => {
  // Check if human object is empty.
  if (Object.keys(human).length === 0) {
    return {
      type: "automated",
      automated
    };
  }
  return {
    type: "human",
    human
  };
};

/**
 * Returns the duration of the audio article
 * @param {object} human The human object
 * @param {object} automated The automated object
 * @returns {number} The duration of the audio article
 */
const getDuration = ({ human, manifest }) => {
  let duration;
  if (/^[\d.]+$/.test(human?.duration)) {
    duration = human.duration;
  } else {
    duration = get(manifest, "voices[0].duration");
  }
  duration = /^[\d.]+$/.test(duration)
    ? Number.parseInt(duration / 1000, 10)
    : undefined;
  return duration;
};

const getAudioArticleCredits = ({ content }) =>
  getCompoundBylineText({
    content,
    roles: ["audio_narrators", "audio_producers"]
  });

/**
 * @param {object} content - The article's content object.
 * @param {object} overrides - The article's overrides object.
 * @param {object} human - The article's human object.
 * @param {object} manifest - The article's manifest object.
 * @param {string} id - The article's ID.
 * @returns {object} - The article's props object.
 */
const getStickyPlayerProps = ({ content, overrides, human, manifest, id }) => {
  const {
    _id,
    rawHeadline,
    section,
    sectionId,
    basicLabel,
    publishDate = "",
    image
  } = fetchProps({
    data: content,
    keys: {
      _id: ["_id"],
      rawHeadline: ["headlines.basic"],
      section: [
        "",
        "taxonomy.primary_section.name",
        "taxonomy.primary_site.name"
      ],
      sectionId: [
        "",
        "taxonomy.primary_section._id",
        "taxonomy.primary_site._id"
      ],
      basicLabel: ["label_display.basic.text"],
      publishDate: ["first_publish_date"],
      image: ["fusion_additions.promo_image.url"]
    },
    overrides: content
  });

  const automated = { manifest };
  const listenText = get(overrides, "audioListenText") || "Listen";

  return {
    id: id + _id,
    audioArticle: getAudioArticleObject({ human, automated }),
    playbackText: listenText || "Listen",
    section,
    headline: (rawHeadline || "").replace(/<b>|<\/b>/g, ""),
    ledeArtEl: getLedeArtElement(image),
    basicLabel,
    initDuration: getDuration({ human, manifest }),
    analytics: {
      metadata: {
        avArcID: id,
        avCMS: "lionfish",
        avName: rawHeadline,
        avSection: sectionId,
        avSystem: "lionfish",
        avSource: "the-washington-post",
        avPlayer: "audio-sticky-hp",
        avPublishDate: publishDate
      },
      useProgress: true,
      useSeek: true
    }
  };
};

/**
 * Creates a player instance for a naked audio url
 * @param {Object} overrides - Override the default props for the player
 * @param {String} streamUrl - URL used to stream the audio
 * @param {String} id - Unique ID for the player
 */
const getStickyPlayerPropsForNakedUrl = (overrides = {}, streamUrl, id) => {
  const automated = {
    ads_url: streamUrl,
    raw_url: streamUrl,
    manifest: null
  };

  const audioArticle = getAudioArticleObject({ automated });
  const listenText = get(overrides, "audioListenText") || "Listen";

  return {
    id,
    audioArticle,
    playbackText: listenText,
    headline: listenText,
    analytics: {
      metadata: {
        avPlayer: "audio-sticky-hp"
      }
    }
  };
};

/**
 * Get podcast data for sticky player
 * @param {Object} content  - content object
 * @param {string} id  - id of the content
 * @param {Object} overrides  - overrides object
 * @returns {Object} - podcast data
 */
const getStickyPlayerPropsForPodcast = (content, overrides, id) => {
  const {
    podtrac_url = "",
    adswizz_url = "",
    complete_url = "",
    url = "",
    _id,
    description = "",
    rawHeadline,
    basicLabel,
    initDuration,
    seriesName,
    image,
    publishDate = "",
    credits
  } = fetchProps({
    data: content,
    keys: {
      podtrac_url: ["additional_properties.audio[0].podtrac_url"],
      adswizz_url: ["additional_properties.audio[0].adswizz_url"],
      complete_url: ["additional_properties.audio[0].complete_url"],
      publishDate: ["first_publish_date"],
      url: ["additional_properties.audio[0].url"],
      _id: ["_id"],
      description: ["description.basic"],
      rawHeadline: ["headlines.basic"],
      basicLabel: ["label_display.basic.text"],
      initDuration: ["additional_properties.audio[0].duration"],
      seriesName: ["additional_properties.series_meta.seriesName"],
      image: ["additional_properties.series_meta.images.coverImage.url"],
      credits: ["credits"]
    }
  });

  const listenText = get(overrides, "audioListenText") || "Listen";

  return {
    id: id + _id,
    podcast: {
      audio_url: complete_url || podtrac_url || url,
      ads_url: complete_url || adswizz_url || url,
      caption: description
    },
    playbackText: listenText,
    section: `${seriesName} : Podcast episode`,
    headline: rawHeadline.replace(/<b>|<\/b>/g, "") || listenText,
    ledeArtEl: getLedeArtElement(image),
    basicLabel,
    initDuration,
    analytics: {
      metadata: {
        avArcID: _id,
        avCMS: "lionfish",
        avSystem: "lionfish",
        avName: rawHeadline,
        avSource: "the-washington-post",
        avPlayer: "audio-sticky-hp",
        avPublishDate: publishDate,
        /* eslint-disable no-use-before-define */
        avHost: getPersonData(credits, "talent").names,
        avHostID: getPersonData(credits, "talent").ids,
        avProducer: getPersonData(credits, "editors").names,
        avProducerID: getPersonData(credits, "editors").ids,
        avContributor: getPersonData(credits, "contributors").names,
        avContributorID: getPersonData(credits, "contributors").ids
      },
      useProgress: true,
      useSeek: true
    }
  };
};

/**
 * Get podcast data for sticky player by slug
 * @param {object} content The article's content object.
 * @param {string} id The article's ID.
 * @returns {object} podcast data
 */
const getStickyPlayerPropsForPodcastBySlug = (content, overrides, id) => {
  // Clean the data, embeddableCardDescription could be null in content.
  if (content.embeddableCardDescription === null) {
    delete content.embeddableCardDescription;
  }

  const {
    initDuration,
    raw_url,
    podTracUrl,
    complete_url,
    basicLabel = "",
    seriesName,
    headline,
    _id,
    createdDate,
    image,
    rawDescription,
    credits,
    publishDate,
    rawHeadline
  } = fetchProps({
    data: content,
    keys: {
      image: ["images.coverImage.url"],
      initDuration: ["duration"],
      raw_url: ["audio.url"],
      podTracUrl: ["audio.podtracUrl"],
      complete_url: ["audio.completeUrl"],
      basicLabel: ["basicLabel.text"],
      seriesName: ["seriesMeta.seriesName"],
      headline: ["title"],
      rawHeadline: ["headlines.basic"],
      _id: ["id"],
      createdDate: ["createdDate"],
      rawDescription: [
        "",
        "embeddableCardDescription",
        "shortDescription",
        "description"
      ],
      credits: ["credits"],
      publishDate: ["first_publish_date"]
    },
    overrides: content
  });

  const listenText = get(overrides, "audioListenText");

  return {
    id: id + get(content, "id", ""),
    section: `${seriesName} : Podcast episode`,
    headline,
    playbackText: listenText || "Listen",
    basicLabel,
    initDuration,
    podcast: {
      audio_url: podTracUrl || raw_url,
      ads_url: complete_url,
      caption: (rawDescription || "").replace(/<p>/g, "").replace(/<\/p>/g, "")
    },
    ledeArtEl: image
      ? {
          resizedURL: getResizedUrl(image, {
            w: 100
          })
        }
      : undefined,
    uuid: _id,
    analytics: {
      metadata: {
        avArcID: _id,
        avCMS: "lionfish",
        avSystem: "lionfish",
        avName: rawHeadline,
        avSource: "the-washington-post",
        avPlayer: "audio-sticky-hp",
        avPublishDate: publishDate,
        /* eslint-disable no-use-before-define */
        avHost: getPersonData(credits, "talent").names,
        avHostID: getPersonData(credits, "talent").ids,
        avProducer: getPersonData(credits, "editors").names,
        avProducerID: getPersonData(credits, "editors").ids,
        avContributor: getPersonData(credits, "contributors").names,
        avContributorID: getPersonData(credits, "contributors").ids
      },
      audioApiData: {
        updatedDate: createdDate
      }
    }
  };
};

const getAudioArticle = ({ content, podcastData, overrides = {}, id }) => {
  /* Goal is:
  {
    // NOTE: needed for web
    duration,
    human,
    manifest,
    inlinePlayer: { listen: string },
    alignment: "string:left|center",

    // NOTE: needed for jsonapp
    contentUrl: string, // canonical_url
    preferredVoice: string, human|automated
    tracking: object, // fusion_additions.tracking
    caption,
    voices: manifest.voices,
    humanVoice: object, // rename human in groomAudioArticleForJsonApp
    ...commonJsonAppKeys
  }
  */

  const {
    audioHide,
    audioAllowArticleType = "human",
    textAlignment = "left"
  } = overrides;

  // NOTE: if audioHide or !audioAllowArticleType, no reason go any further
  if (audioHide || /none/.test(audioAllowArticleType)) return undefined;

  const audioArticleData = getAudioArticleData({
    content,
    podcastData,
    overrides
  });
  if (!audioArticleData) return undefined;

  let {
    human = {},
    /* eslint-disable prefer-const */
    manifest,
    authorNarratedFlag = false,
    sectionId
    /* eslint-enable prefer-const */
  } = fetchProps({
    data: audioArticleData,
    keys: {
      manifest: ["additional_properties.audio_article.automated.manifest"],
      human: ["additional_properties.audio_article.human"],
      authorNarratedFlag: [
        "additional_properties.audio_article.human.authorNarratedFlag"
      ],
      sectionId: [
        "",
        "taxonomy.primary_section._id",
        "taxonomy.primary_site._id"
      ]
    },
    overrides
  });

  const jsonAppProps = fetchProps({
    data: audioArticleData,
    keys: {
      preferredVoice: ["additional_properties.audio_article.type"],
      tracking: ["fusion_additions.tracking"],
      ...commonJsonAppKeys
    },
    overrides
  });

  const link = getLink({ content: audioArticleData });
  jsonAppProps.contentUrl = get(link, "link.url", undefined);

  // const isBackingContent = audioArticleData === content;
  // if (isBackingContent) {
  //   const headline = getHeadline({ content, overrides })
  //   jsonAppProps.title = headline.text;
  // }

  let audioType = "audio-article";
  let streamUrl;
  let caption;

  // NOTE: For human-read audio, faking raw audio behavior by
  //   1. not setting audioType to "audio-article"; and
  //   2. setting streamUrl
  if (human?.raw_url) {
    audioType = "audio-article-human";
    streamUrl = human.raw_url;
    caption =
      human?.caption || getAudioArticleCredits({ content: audioArticleData });

    human = {
      authors: getAuthorObjects({ content: audioArticleData }),
      // Use the human caption if it exists, otherwise use the getAudioArticleCredits.
      caption,
      authorNarratedFlag,
      isTheSeven: sectionId?.includes("the-seven"),
      ...human
    };
  }

  // NOTE: If no manifest voices and no streamUrl (human) return undefined.
  if (!manifest && !manifest?.voices.length && !streamUrl) return undefined;

  return {
    audioType,
    streamUrl,
    duration: getDuration({ human, manifest }),
    stickyPlayerAudioProps: getStickyPlayerProps({
      content: audioArticleData,
      overrides,
      human,
      manifest,
      id
    }),
    human,
    manifest,
    inlinePlayer: getTextObject({ overrides }),
    alignment: textAlignment,
    playerMedia: getPromoImage({ content: audioArticleData }),
    caption,
    ...jsonAppProps
  };
};

export const getAudio = ({
  content,
  podcastData,
  overrides = {},
  id
  // editableContentObj,
  // isAdmin
}) => {
  /* Goal is:
  {
    mediaId: "string",
    duration: int,
    streamUrl: "url:.mp3|.ogg|.wav",
    showSubscribe: boolean,
    subscriptionLinks: [
      {
        text: "string",
        url: "string:url",
        type: "web",
        id: "string:applePodcasts|googlePlay|etc."
      }
      ...
    ],
    inlinePlayer: { listen: string },
    alignment: "string:left|center"
  }
  */

  // NOTE: Short-circuit here if it's an audio article
  const audioArticle = getAudioArticle({ content, podcastData, overrides, id });
  if (audioArticle) return audioArticle;

  const {
    audioHide,
    audioListenText,
    audioUrl,
    textAlignment = "left"
  } = overrides;

  // NOTE: if audioHide, no reason go any further
  if (audioHide) return undefined;

  const subtype = get(content, "subtype");

  const extRe = /\.(mp3|ogg|wav)(\?|#|$)/i;
  let {
    streamUrl,
    mediaId,
    url,
    podTracUrl,
    adswizzUrl,
    subscriptionLinks,
    isPodcastContent,
    podcastJsonAppProps,
    stickyPlayerAudioProps
  } = {};
  if (audioUrl && audioUrl.match(/^https?:\/\//) && audioUrl.match(extRe)) {
    const sslProxy = "https://proxy.washingtonpost.com/proxy/simple?url=";
    streamUrl = audioUrl.match(/https:\/\//)
      ? audioUrl
      : `${sslProxy}${encodeURIComponent(audioUrl)}`;
    stickyPlayerAudioProps = getStickyPlayerPropsForNakedUrl(
      overrides,
      streamUrl,
      id
    );
    podcastJsonAppProps = {
      coverImage: {
        url: "https://podcast.posttv.com/basic_images/social.png",
        width: 1300,
        height: 1300
      },
      seriesSlug: "naked",
      slug: kebabcase(audioListenText || "Listen"),
      title: audioListenText
    };
    isPodcastContent = false;
  } else if (podcastData || subtype === "audio") {
    if (
      (!podcastData && content && subtype === "audio") ||
      (podcastData && podcastData.additional_properties)
    ) {
      // this means it came from prism either via backing content or audio canonical url
      const contentToUse = podcastData || content;
      stickyPlayerAudioProps = getStickyPlayerPropsForPodcast(
        contentToUse,
        overrides,
        id
      );
      ({ mediaId, url, podTracUrl, adswizzUrl, subscriptionLinks } = fetchProps(
        {
          data: contentToUse,
          keys: {
            mediaId: ["fusion_additions.audio.id"],
            url: ["additional_properties.audio[0].url"],
            podTracUrl: ["additional_properties.audio[0].podtrac_url"],
            adswizzUrl: ["additional_properties.audio[0].adswizz_url"],
            subscriptionLinks: [
              "additional_properties.series_meta.subscriptionLinks",
              {}
            ],
            ...commonJsonAppKeys
          },
          overrides
        }
      ));
      podcastJsonAppProps = fetchProps({
        data: contentToUse,
        keys: podcastJsonAppKeys,
        overrides: contentToUse
      });
    } else if (podcastData) {
      stickyPlayerAudioProps = getStickyPlayerPropsForPodcastBySlug(
        podcastData,
        overrides,
        id
      );
      ({ mediaId, url, podTracUrl, adswizzUrl, subscriptionLinks } = fetchProps(
        {
          data: podcastData,
          keys: {
            mediaId: ["id"],
            url: ["audio.url"],
            podTracUrl: ["audio.podTracUrl"],
            adswizzUrl: ["audio.adswizzUrl"],
            publicationDisplayDate: ["publicationDisplayDate"],
            subscriptionLinks: ["seriesMeta.subscriptionLinks", {}]
          },
          overrides
        }
      ));
      podcastJsonAppProps = fetchProps({
        data: podcastData,
        keys: podcastJsonAppKeys,
        overrides: podcastData
      });
    }

    streamUrl = adswizzUrl || podTracUrl || url;
    isPodcastContent = !!podTracUrl;
  }

  // NOTE: If after all that and there is not stream url, return undefined
  if (!streamUrl) return undefined;

  const audioType = mediaId ? "podcast" : "naked";

  return {
    audioType,
    mediaId,
    streamUrl,
    stickyPlayerAudioProps,
    subscriptionLinks: isPodcastContent ? subscriptionLinks : undefined,
    inlinePlayer: getTextObject({ overrides }),
    alignment: textAlignment,
    isPodcastContent,
    ...podcastJsonAppProps
  };
};

getAudio.propTypes = {
  content: PropTypes.object,
  podcastData: PropTypes.object,
  overrides: PropTypes.object
  // isAdmin: PropTypes.bool
};

function getPersonData(credits, role) {
  switch (role) {
    case "contributors":
      break;
    case "editors":
      role = get(credits, "editors") ? "editors" : "by";
      break;
    case "talent":
    default:
      role = get(credits, "hostTalents") ? "hostTalents" : "host_talent";
  }

  const names = [];
  const ids = [];
  get(credits, role, []).forEach((person) => {
    if (person.name) {
      names.push(person.name);
    }
    const id = get(
      person,
      "_id",
      get(person, "id", get(person, "referent.id"))
    );
    if (id && !/-1/.test(id)) {
      ids.push(id);
    }
  });
  return {
    names: names.join(";"),
    ids: ids.join(";")
  };
}

/*
 * @param {string} coverImage - image object in lionfish form
 *
 * @returns {obj} -- media object in jsonapp form
 */
const getPodcastImage = ({ coverImage = {} }) => {
  const { url, width, height } = coverImage;
  const aspectRatio = width && height ? width / height : undefined;
  return url ? { url, aspectRatio, mediaType: "image" } : undefined;
};

/*
 * @param {string} seriesName - the series name
 *
 * @returns {obj} -- a label object
 */
const getPodcastLabel = ({ seriesName }) =>
  seriesName ? { type: "kicker", label: { text: seriesName } } : undefined;

/*
 * @param {bool} isPodcastContent - will be false if naked audio url
 * @param {string} publicationDisplayDate - date formatted as YYYYMMDD
 * @param {string} seriesSlug - kebab-cased string representing the series slug
 * @param {string} slug - kebab-cased string representing the episode slug
 *
 * @returns {obj} -- the tracking object needed for the apps
 */
const getPodcastTracking = ({
  isPodcastContent,
  publicationDisplayDate,
  seriesSlug,
  slug
}) => ({
  audioName: `${
    isPodcastContent ? "podcasts" : "audio"
  }:${seriesSlug}:${publicationDisplayDate}:${slug}`.toLowerCase(),
  seriesSlug
});

/*
 * @param {string} title -- The title
 * @param {string} slug -- The slug
 * @param {string} displaDate -- ISO-8601 formatted date
 *
 * @returns {obj} -- data needed for the apps
 */
const getPodcastProps = (props) => {
  const { displayDate, slug, title } = props;
  return {
    title,
    slug,
    displayDate,
    label: getPodcastLabel(props),
    playerMedia: getPodcastImage(props),
    tracking: getPodcastTracking(props)
  };
};

export const groomAudioForJsonApp = ({ audio }) => {
  if (!audio) return undefined;

  const isAnArticle = audio.manifest || audio.human;
  const isAPodcast = !isAnArticle;

  // START: clean-up
  if (isAPodcast) {
    if (/.*\/(.*)\/$/.test(audio.slug)) audio.slug = `${RegExp.$1}`;
    if (/^\d+$/.test(audio.displayDate))
      audio.displayDate = format(
        new Date(audio.displayDate),
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      );
    // END: clean-up

    audio = { ...audio, ...getPodcastProps(audio) };
  }

  delete audio.alignment;
  delete audio.stickyPlayerAudioProps;
  delete audio.isPodcastContent;
  delete audio.publicationDisplayDate;
  delete audio.coverImage;
  delete audio.seriesSlug;
  delete audio.seriesName;

  if (!/human/.test(audio.preferredVoice)) delete audio.human;

  if (isAnArticle) audio = groomAudioArticleForJsonApp({ audio });

  if (audio.tracking) {
    delete audio.tracking.blog_name;
    delete audio.tracking.clavis_keywords;
    delete audio.tracking.cms_system;
    delete audio.tracking.commercial_node;
    delete audio.tracking.content_category;
    delete audio.tracking.hierarchy;
    delete audio.tracking.seo_keywords;
    if (/(?:.*\s-\s){3}(.*)/.test(audio?.tracking?.page_name))
      audio.tracking.page_name = `${RegExp.$1} `;
  }

  // NOTE: Clean up and sort, for fun
  audio = Object.keys(audio)
    .sort()
    .reduce((acc, key) => {
      const value = audio[key];
      if (value !== "" && value !== null && value !== undefined)
        acc[key] = audio[key];
      return acc;
    }, {});

  return audio;
};

export const getAudioArticleLabel = (audio) => {
  if (!audio) return undefined;
  let { displayLabel, displayLabelUrl, displayTransparency } = audio;
  let type = "kicker";

  // NOTE: If only xparency label, use it
  if (!displayLabel) {
    displayLabel = displayTransparency;
    displayLabelUrl = undefined;
    displayTransparency = undefined;
  }

  // NOTE: Exclusive label treated differently
  const isExclusive = /Exclusive/i.test(displayLabel);
  if (isExclusive) {
    type = "exclusive-pill";
    displayTransparency = undefined;
  }

  if (displayLabel) {
    return {
      type,
      label: { text: displayLabel },
      ...(displayTransparency
        ? { labelSecondary: { text: displayTransparency } }
        : {}),
      ...(displayLabelUrl
        ? { link: { url: displayLabelUrl, type: "unknown" } }
        : {})
    };
  }
  return undefined;
};

// START: rich captions -- needed for jsonapp and sticky player
// NOTE: The sticky player needs the rich caption in React.
// The jsonapp needs it as a string. Hence, this data object
// which can is transformed to React for sticky player and a string.
// for jsonapp.
export const richCaptions = {
  // NOTE: Add more cases as necessary
  noa: {
    template: "Read and produced by Noa. Listen more on the {link}.",
    link: {
      url: "https://newsoveraudio.com/",
      text: "Noa app"
    }
  }
};

/**
 * Get the rich caption type based on the caption
 * @param {string} caption
 * @returns {Object} The rich caption data
 */
export const getRichCaptionType = (caption) => {
  // NOTE: Add more cases as necessary
  if (/Read and produced by Noa/i.test(caption)) {
    return "noa";
  }
  return undefined;
};

/**
 * Get the rich caption as string needed for jsonapp
 * @param {string} caption
 * @returns {Object} The rich caption data
 */
const getRichCaption = (caption) => {
  const richCaption = richCaptions?.[getRichCaptionType(caption)];
  if (richCaption) {
    const a = richCaption.template.split("{link}");
    return `${a[0]}<a href="${richCaption.link.url}">${richCaption.link.text}</a>${a[1]}`;
  }
  return undefined;
};
// END: rich captions

export const groomAudioArticleForJsonApp = ({ audio }) => {
  if (!audio) return undefined;

  audio.humanVoice = audio?.human;
  audio.voices = audio?.manifest?.voices;

  delete audio.duration;
  delete audio.human;
  delete audio.manifest;
  delete audio.streamUrl;

  if (/^Listen$/.test(audio?.inlinePlayer?.listen))
    delete audio.inlinePlayer.listen;
  if (!Object.keys(audio?.inlinePlayer || {}).length) delete audio.inlinePlayer;

  if (audio.excerpt) audio.excerpt = { text: audio.excerpt };

  // NOTE: duration is in milliseconds, transform to seconds
  // START: voices duration
  if (audio?.voices?.length) {
    audio.voices = audio.voices.map((voice) => {
      let { duration } = voice;
      duration = /^[\d.]+$/.test(duration)
        ? Math.round(duration / 1000)
        : undefined;
      const obj = {
        voiceId: voice.id,
        ...voice,
        duration
      };
      delete obj.id;
      delete obj.s3Key;
      delete obj.marksUrl;
      return obj;
    });
  }
  // END: voices duration

  // START: human duration
  if (audio?.humanVoice) {
    const { duration } = audio.humanVoice;
    audio.humanVoice = (() => {
      const obj = {
        ...audio.humanVoice,
        duration: /^\d+$/.test(duration)
          ? Math.round(duration / 1000)
          : undefined
      };
      delete obj.authorNarratedFlag;
      delete obj.authors;
      delete obj.caption;
      delete obj.isTheSeven;
      delete obj.source_file_id;
      return obj;
    })();
  }
  // END: human duration

  // START: make audio label
  if (audio.displayLabel === audio.displayTransparency)
    delete audio.displayTransparency;
  audio.label = getAudioArticleLabel(audio);

  delete audio.displayLabel;
  delete audio.displayLabelUrl;
  delete audio.displayTransparency;
  // END: make audio label

  if (audio.caption) {
    const richCaption = getRichCaption(audio.caption);
    audio.richCaption = {
      text: richCaption || audio.caption,
      mime: richCaption ? "text/html" : undefined
    };
    // TODO: delete audio.caption when app adoption is high enough
  }

  return audio;
};

groomAudioForJsonApp.propTypes = {
  headline: PropTypes.object
};
