import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Warning from "~/shared-components/Warning";
import getEnv from "../components/utilities/env";

class ComponentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    if (getEnv() !== "prod") {
      /* eslint-disable no-console */
      console.error(error);
      console.log(errorInfo);
      /* eslint-enable no-console */
    }

    this.setState({
      hasError: true,
      message: error.message
    });
  }

  render() {
    const level = this.props.level || "error";
    const message = (
      <Fragment>
        <b>{level.toUpperCase()}</b> Rendering fallback b/c:{" "}
        {this.state.message}
      </Fragment>
    );
    const ErrorComponent = this.props.showErrorMessage ? (
      <Fragment>
        {this.props.FallbackComponent}
        <Warning message={message} level={level} />
      </Fragment>
    ) : (
      this.props.FallbackComponent
    );

    return this.state.hasError ? ErrorComponent || null : this.props.children;
  }
}

ComponentErrorBoundary.propTypes = {
  showErrorMessage: PropTypes.bool,
  FallbackComponent: PropTypes.node,
  children: PropTypes.node,
  errorTag: PropTypes.string,
  level: PropTypes.oneOf(["info", "warning", "error"])
};

export default ComponentErrorBoundary;
