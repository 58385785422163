// TODO rename this file and functions
import get from "lodash.get";
import { hasLocalStorage } from "../hasLocalStorage";
import { getLoginDetails } from "../login-details";
import { getQueryParams } from "../history";
import getCookie from "../cookies";
import getEnv from "../env";

/**
 * @func referrerGroup
 * @desc Identify the type of the referrer for use in Zeus Insights.
 * @return {String}
 */
function referrerGroup() {
  let ref = "direct";
  const r = document.referrer;
  const regTests = {
    social: new RegExp(
      // eslint-disable-next-line max-len
      /facebook\.com|digg\.com|\/t\.co|reddit\.com|newstrust\.net|twitter\.com|linkedin\.com|instagram\.com|snapchat|tumblr\.com|linkin\.bio/i
    ),
    search: new RegExp(
      /google\.com|bing\.com|yahoo\.com|duckduckgo\.com|search\.aol\.com/
    ),
    news: new RegExp(/ampproject\.org|alert|msn\.com|pocket|smartnews|apple/i),
    app: new RegExp(
      /nativeapp\.toutiao\.com|flipboard\.com|newsbreakapp\.com|viber/i
    ),
    fbnotify: new RegExp(/notify\.co/i)
  };
  Object.keys(regTests).forEach((k) => {
    if (regTests[k].test(r)) {
      ref = k;
    }
  });
  if (
    typeof window !== "undefined" &&
    window.location.search.match("wpisrc=")
  ) {
    ref = "email";
  }
  return ref;
}
/**
 * @func generateUUID
 * @desc generate a UUID for use in Clavis service
 * @return {String}
 */
export function generateUUID() {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

/**
 * @func getUUID
 * @desc retrieve a UUID from localStorage
 *   if there is none, generate one save it to localStorage and return the value
 * @return {String}
 */
function getUUID() {
  let uuid = hasLocalStorage() && localStorage.getItem("uuid");
  if (!uuid) {
    uuid = generateUUID();
    if (hasLocalStorage()) localStorage.setItem("uuid", uuid);
  }
  return uuid;
}

/**
 * @func normalizeURL
 * @desc Standardize the format of the URL to be sent in to the tracking service.
 * Will take a URL like http://www.washingtonpost.com/foo/bar?bar=foo
 * and return it as `www.washingtonpost.com/foo/bar`
 * @return {String}
 */
function normalizeURL(url) {
  let str = (url || "").trim();
  str = str.replace(/^(https?:)?\/\//, ""); // remove protocol
  str = str.replace(/[?#].*/, ""); // remove query string & hash
  return str;
}

/**
 * @func getAllRead
 * @desc Retrieve the array of the last 50 articles the user has read
 * @return {Object}
 */
export function getAllRead() {
  let items;
  // just in case localStorage gets corrupted we should
  // protect against parse throwing here
  // return an empty array if not so that we can proceed
  try {
    items = JSON.parse(localStorage.getItem("readlist")) || [];
  } catch (e) {
    items = [];
  }
  return items;
}

/**
 * @func addToReadList
 * @desc Add the current url to the array of the last 50 articles the user has read
 * @return {null}
 */
export function addToReadList(url) {
  // getAllRead should always return an array
  const items = getAllRead();
  // if the url already exists in our array we don't add it
  if (items.filter((i) => i.u === url).length) {
    return;
  }
  items.push({
    u: url,
    t: Date.now()
  });
  if (items.length > 50) {
    items.shift();
  }
  if (hasLocalStorage()) {
    localStorage.setItem("readlist", JSON.stringify(items));
  }
}

/**
 * @func createZISessionTracking
 * @desc Factory to create an object to describe the Zeus Insights Session storage structure
 * and handle its functions.
 * @return {Object}
 */
export function createZISessionTracking() {
  const sessionTrackingObject = {
    session_expiration: 30 * 60 * 1000 + Date.now(), // 30 minutes from now we expire the session.
    session_start: Date.now(), // Session starts now.
    session_count: "0", // Increment with each article
    session_id: "NA", // Generated
    articles: [] // Store articles per session.
  };
  if (typeof document === "undefined") {
    sessionTrackingObject.original_referrer = "";
    sessionTrackingObject.original_referrer_group = "direct";
  } else {
    sessionTrackingObject.original_referrer = normalizeURL(document.referrer); // Determine the referrer sans http
    sessionTrackingObject.original_referrer_group = referrerGroup(); // Mapping of referrer URLs to particular types.
  }
  return sessionTrackingObject;
}
/**
 * @func addArticleToSessionTracking
 * @desc Add the current article to the ZI Session Tracking Object.
 * @return {null}
 */
export function addArticleToSessionTracking(clavisData, sessionTrackingObject) {
  sessionTrackingObject.articles.push({
    auxiliaries: clavisData.auxiliaries,
    article_id: clavisData.currentUrl,
    timestamp: Date.now()
  });
  const addOne = parseInt(sessionTrackingObject.session_count, 10) + 1;
  sessionTrackingObject.session_count = `${addOne}`;
}
/**
 * @func loadSessionTracking
 * @desc Retrieve zip session from local storage and load it into your object
 * @return {boolean} - indicating if it successfully loaded data from local storage.
 */
export function loadSessionTracking(sessionTrackingObject) {
  let loadSuccess = true;
  try {
    const ziSession = JSON.parse(localStorage.getItem("zeus_insights_session"));
    if (Date.now() > ziSession.session_expiration) {
      // Session has expired, let's restart
      localStorage.removeItem("zeus_insights_session");
    } else {
      // switch session expiration to 30 min from now.
      delete ziSession.session_expiration;
      Object.assign(sessionTrackingObject, ziSession);
    }
  } catch (e) {
    // localStorage key is empty./
    loadSuccess = false;
  }
  if (
    !sessionTrackingObject.session_id ||
    sessionTrackingObject.session_id === "NA"
  ) {
    sessionTrackingObject.session_id = `${getUUID()}.${
      sessionTrackingObject.session_start
    }`;
  }
  return loadSuccess;
}

/**
 * @func saveSessionTracking
 * @desc Saves zip session from object and load it into local storage
 * @return {null}
 */
function saveSessionTracking(sessionTrackingObject) {
  if (hasLocalStorage()) {
    localStorage.setItem(
      "zeus_insights_session",
      JSON.stringify(sessionTrackingObject)
    );
  }
}

/**
 * @func clavisService
 * @desc setup and start a clavis service
 * @param {Object} options - the options to set on clavis service
 */
// TODO: this function should be renamed to "clickstreamService", as this
// sends data to the targeting clickstream maintained by the #personalization team.
// This functionality has been separated out from the clavis topic tagging system
function clavisService(options) {
  if (typeof window === "undefined") {
    throw new Error(
      "You are attempting to invoke clavis tracking in a non browser environment"
    );
  }
  if (!hasLocalStorage()) {
    // eslint-disable-next-line no-console
    console.error(
      "Local Storage does not exist or is not writeable.  Clavis depends on localStorage"
    );
    return;
  }
  const clavis = options;
  const ziSessionData = createZISessionTracking();
  loadSessionTracking(ziSessionData);
  if (
    clavis &&
    clavis.contentId &&
    clavis.contentId.length > 0 &&
    clavis.targetingUrl &&
    clavis.targetingUrl.length > 0 &&
    clavis.currentUrl
  ) {
    addArticleToSessionTracking(clavis, ziSessionData);

    let gaId;

    // The last two parts of the GA cookie represents the GA Client ID
    // Code taken from the Zeus Library since they need to match
    if (clavis.gaCookie) {
      const fields = clavis.gaCookie.split(".");
      if (fields.length > 3) {
        const gaSet = [fields[2], fields[3]];
        gaId = gaSet.join(".");
      }
    }

    // Old school AJAX , no jQuery. IE9+.
    const request = new XMLHttpRequest();
    request.open("POST", clavis.targetingUrl, true);
    request.setRequestHeader("Content-Type", "application/json");

    request.onload = function requestOnLoad() {
      if (request.status >= 200 && request.status < 400) {
        if (hasLocalStorage()) {
          localStorage.setItem("clavis.targeting", request.responseText);
        }
      } else {
        // Log / Ignore
      }
    };
    const url = clavis.currentUrl;
    // We are retaining the trigger of adding the url to the readlist here (even though it is used later)
    // This is in order to execute the addition to the readlist early.
    addToReadList(url);
    request.send(
      JSON.stringify({
        auxiliaries: clavis.auxiliaries,
        articleid: `contentapi://${clavis.contentId}`,
        referrer: normalizeURL(document.referrer),
        external_referrer_group: ziSessionData.original_referrer_group,
        userid: getUUID(),
        wapo_login_id: clavis.wapoLoginID,
        tid: clavis.tid,
        url,
        session_id: ziSessionData.session_id,
        session_number: ziSessionData.session_count,
        ga_id: gaId,
        carta_id: clavis.wpCartaId
      })
    );
  }
  saveSessionTracking(ziSessionData);
}

export default clavisService;

/**
 *
 * @param {object} globalContent
 * @returns {object} - the clavis configuration
 */
export function contentToClavisConfig(globalContent) {
  const { wapoLoginID } = getLoginDetails();
  const tid = getQueryParams(window.location.href).tid || "";
  const aux = get(window, "wpAdFusion.clavis.auxiliaries", []);
  const wpCartaId = getCookie("wp_crtid");
  const gaCookie = getCookie("_ga");

  return {
    targetingUrl: /prod/.test(getEnv())
      ? "https://targeting.washingtonpost.com/api/v1/targeting"
      : "https://targeting-staging.washingtonpost.com/api/v1/targeting",
    auxiliaries: aux,
    contentId:
      globalContent._id || globalContent.siteServiceSection || "homepage",
    wapoLoginID,
    tid,
    gaCookie,
    wpCartaId
  };
}
