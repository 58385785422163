import PropTypes from "prop-types";

export const LiveVideoPropTypes = {
  dismiss: PropTypes.func,
  customFields: PropTypes.shape({
    customPrimaryVideo: PropTypes.oneOf(
      Object.keys({
        "Video 1": "Video 1",
        "Video 2": "Video 2",
        "Video 3": "Video 3"
      })
    ).tag({
      defaultValue: "Video 1",
      name: "Primary Video"
    }),
    displayWithoutVideo: PropTypes.bool.tag({
      defaultValue: false,
      name: "Display Without Video"
    }),
    customBtnText: PropTypes.richtext.tag({
      name: "Custom Button Text",
      hidden: true
    }),
    video1Url: PropTypes.richtext.tag({
      name: "Video 1 Url"
    }),
    video1HeadlinePrefix: PropTypes.richtext.tag({
      // group: "Video 1 Overrides",
      name: "Headline Prefix-Video 1",
      defaultValue: "WATCH LIVE: "
    }),
    video1Headline: PropTypes.richtext.tag({
      // group: "Video 1 Overrides",
      name: "Headline-Video 1"
    }),
    video1HeadlineLink: PropTypes.richtext.tag({
      // group: "Video 1 Overrides",
      name: "Headline Link-Video 1",
      hidden: true
    }),
    video1AltArt: PropTypes.richtext.tag({
      // group: "Video 1 Overrides",
      name: "Alternate Art-Video 1"
    }),
    video2Url: PropTypes.richtext.tag({
      group: "Video 2 Overrides",
      name: "url-Video 2"
    }),
    video2HeadlinePrefix: PropTypes.richtext.tag({
      group: "Video 2 Overrides",
      name: "Headline Prefix",
      defaultValue: "WATCH LIVE: "
    }),
    video2Headline: PropTypes.richtext.tag({
      group: "Video 2 Overrides",
      name: "Headline-Video 2"
    }),
    video2HeadlineLink: PropTypes.richtext.tag({
      group: "Video 2 Overrides",
      name: "Headline Link-Video 2",
      hidden: true
    }),
    video2AltArt: PropTypes.richtext.tag({
      group: "Video 2 Overrides",
      name: "Alternate Art-Video 2"
    }),
    video3Url: PropTypes.richtext.tag({
      group: "Video 3 Overrides",
      name: "url-Video 3"
    }),
    video3HeadlinePrefix: PropTypes.richtext.tag({
      group: "Video 3 Overrides",
      name: "Headline Prefix",
      defaultValue: "WATCH LIVE: "
    }),
    video3Headline: PropTypes.richtext.tag({
      group: "Video 3 Overrides",
      name: "Headline-Video 3"
    }),
    video3HeadlineLink: PropTypes.richtext.tag({
      group: "Video 3 Overrides",
      name: "Headline Link-Video 3",
      hidden: true
    }),
    video3AltArt: PropTypes.richtext.tag({
      group: "Video 3 Overrides",
      name: "Alternate Art-Video 3"
    })
  })
};
